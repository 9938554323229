import {
  QuestionInterface,
  QuestionRatingType,
} from "../interfaces/question.interface";
import axios from "axios";

const env = process.env;

export const getAnswerService = (questionText: string,user_id: string,session_id: string,parentquestionid:string,user_name:string): Promise<any> => {
  const parent_question_id = parentquestionid;
  const user_input  =  questionText;
 
  return axios.post(`${env.REACT_APP_API_URL}/get_response`, {
    user_input, user_id,session_id,parent_question_id,user_name,
  });

 };


export const rateQuestionService = (
  question: QuestionInterface,
  rating1: QuestionRatingType,
) => {
  // return axios.post(`${env.REACT_APP_API_URL}/rate_question`, {
  //   question,
  //   rating,
  // });
  var question_id  = question.question_id;
  var default_topic=question.default_topic;
  var session_id = question.user?.sessionId;
  var feedback = question.remarks;
  var user_id = question.user?.userId;
  var user_topic = question.userTopic;
  var rating='';
  var remarks = question.feedback;
  var parent_question_id =question.parent_question_id;
  
  if(user_topic !==null && user_topic !== undefined){
    default_topic = "";
  }
  if(rating1 === 1){
    rating="up";
    feedback=[];
    remarks="";
  }
  else if(rating1 === 2){
    rating="down";
    feedback=question.remarks;
    user_topic="";
    remarks=question.feedback;
  }
  else if(rating1 === 0){
    rating="rating-removed";
    //user_topic="";
    feedback=[];
    remarks="";
  } 
  return axios.post(`${env.REACT_APP_API_URL}/rate_question`, {
    question_id,
    default_topic,
    rating,
    session_id,
    feedback,
    user_id,
    user_topic, 
    remarks,
    parent_question_id,
  });
};
export const clearSession = (user_id: string,session_id: string) => {
  return axios.post(`${env.REACT_APP_API_URL}/clear_session`, {
    user_id,
    session_id,
  })
  .then(()=>{
    return Promise.reject({
      response:{
               status:500,
               data: "Internal Server Error",
           },
    });
  })

};
export const generalFeedback = (user_id: string,session_id: string,user_name:string,feedback:string,remarks:string): Promise<any> => {
  
  return axios.post(`${env.REACT_APP_API_URL}/general_feedback`, {
   user_id,session_id,user_name,feedback,remarks,
  });

 };
export const gethistory = (user_id: string,user_name:string): Promise<any> => {
 
  return axios.post(`${env.REACT_APP_API_URL}/chat_history/recent`, {
   user_id,user_name,
  })
  .then(response =>{
    return{
      status:500,
      data:{
        status: "Failed",
        message: "An ERROR occurred: HTTPStatusError - Server error '500 Internal Server Error' for url 'https://scem-p-afa-fet-001.azurewebsites.net/api/chat_history'\nFor more information check: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500",
        status_code: 500,
      },
    };

  })
 
 };
 export const getsession = (user_id: string,session_id: string,user_name:string): Promise<any> => {
 
  return axios.post(`${env.REACT_APP_API_URL}/fetch_session`, {
   user_name,user_id,session_id
  });
 
 };

 export const getrename = (user_id: string,session_id: string,session_name:string,action:string): Promise<any> => {
 
  return axios.post(`${env.REACT_APP_API_URL}/update_session`, {
   user_id,session_id,session_name,action,
  });
 
 };
 export const getchathistory = (user_id: string,user_name:string): Promise<any> => {
 
  return axios.post(`${env.REACT_APP_API_URL}/chat_history/archive`, {
   user_id,user_name,
  })
  .then(response =>{
    return{
      status:500,
      data:{
        status: "Failed",
        message: "An ERROR occurred: HTTPStatusError - Server error '500 Internal Server Error' for url 'https://scem-p-afa-fet-001.azurewebsites.net/api/chat_history'\nFor more information check: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500",
        status_code: 500,
      },
    };

  })
 };
 export const searchhistory = (user_id: string,user_name:string,query:string): Promise<any> => {
 
  return axios.post(`${env.REACT_APP_API_URL}/chat_history/archive/search`, {
   user_id,user_name,query
  });
};
