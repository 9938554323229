// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-actions-icon, .more-actions-icon:hover {
  position: absolute !important;
  top: 76px;
  right: 9px;
  background: white !important;
  color: black !important;
}

.close-icon {
  position: absolute !important;
  top: 8px;
  right: 20px;
  color: white !important;
}

.divider {
  color: white !important;
  background: white !important;
  border-width: 2px !important;
  border-color: white !important;
  opacity: 1 !important;
}

.drawer-paper {
  color: #fff;
  background-color: #0a0a0a;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: unset !important;
}

.indexbtn:hover {
  background-color: #fff !important;
  text-decoration: underline;
}

.indexbtn.active {
  background-color: #fff !important;
  text-decoration: underline;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/historyDrawer/historyDrawer.component.scss","webpack://./src/base.scss"],"names":[],"mappings":"AAGA;EACI,6BAAA;EAEA,SAAA;EACA,UAAA;EACA,4BAAA;EACA,uBAAA;AAHJ;;AAMA;EACI,6BAAA;EACA,QAAA;EACA,WAAA;EACA,uBAAA;AAHJ;;AAOA;EACI,uBAAA;EACA,4BAAA;EACA,4BAAA;EACA,8BAAA;EACA,qBAAA;AAJJ;;AAOA;EACI,WAAA;EACA,yBC7BkB;ADyBtB;;AAMA;EACI,kCAAA;AAHJ;;AAKA;EACI,iCAAA;EACA,0BAAA;AAFJ;;AAIA;EACI,iCAAA;EACA,0BAAA;EACA,iBAAA;AADJ","sourcesContent":["\n@use \"../../base.scss\";\n\n.more-actions-icon, .more-actions-icon:hover {\n    position: absolute !important;\n    // bottom: 50px;\n    top:76px;\n    right: 9px;\n    background: white !important;\n    color: black !important;\n}\n\n.close-icon {\n    position: absolute !important;\n    top: 8px;\n    right: 20px;\n    color: white !important;\n}\n\n\n.divider {\n    color: white !important;\n    background: white !important;\n    border-width: 2px !important;\n    border-color: white !important;\n    opacity: 1 !important;\n}\n\n.drawer-paper {\n    color: #fff;\n    background-color: base.$theme-primary-color;\n}\n.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{\n    background-color: unset !important;\n}\n.indexbtn:hover {\n    background-color: #fff !important;\n    text-decoration: underline;\n}\n.indexbtn.active {\n    background-color: #fff !important;\n    text-decoration: underline;\n    font-weight: bold;\n}\n","$font-family: 'Segoe UI';\n$theme-primary-color: #0a0a0a;\n$theme-secondary-color: #FED141;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
