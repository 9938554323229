import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
// import logo from './logo.svg';
import CustomRouter from './router';
import theme from './theme';
import './App.scss';

function App() {
  return (
    <ThemeProvider theme={theme}>

      {<CustomRouter />}

    </ThemeProvider>
  );
}

export default App;
