import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { FileInterface } from "../../../interfaces/files.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { setSelected } from "../../../stores/slices/file.slice";

// const rows: FileInterface[] = [
//   createFileInterface("Cupcake", new Date().getTime()),
//   createFileInterface("Donut", new Date().getTime()),
//   createFileInterface("Eclair", new Date().getTime()),
//   createFileInterface("Frozen yoghurt", new Date().getTime()),
//   createFileInterface("Gingerbread", new Date().getTime()),
//   createFileInterface("Honeycomb", new Date().getTime()),
//   createFileInterface("Ice cream sandwich", new Date().getTime()),
//   createFileInterface("Jelly Bean", new Date().getTime()),
//   createFileInterface("KitKat", new Date().getTime()),
//   createFileInterface("Lollipop", new Date().getTime()),
//   createFileInterface("Marshmallow", new Date().getTime()),
//   createFileInterface("Nougat", new Date().getTime()),
//   createFileInterface("Oreo", new Date().getTime()),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof FileInterface;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "fileName",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "dateCreated",
    numeric: true,
    disablePadding: false,
    label: "Uploaded On",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof FileInterface
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof FileInterface) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{
              width: headCell.id === "fileName" ? "75%" : "auto",
              fontWeight: "bold",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  deleteFiles: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, deleteFiles } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", textAlign: "center" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Files Uploaded
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              deleteFiles();
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <span></span>
      )}
    </Toolbar>
  );
}

const FileListComponent = (props: { deleteSelectedFiles: any }) => {
  const dispatch = useDispatch();
  const fileReducer = useSelector((state: RootState) => state.files);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof FileInterface>("dateCreated");
  //   const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof FileInterface
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = fileReducer.files.map((n) => n.fileName);
      dispatch(setSelected(newSelected));
      return;
    }
    dispatch(setSelected([]));
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = fileReducer.selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(fileReducer.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(fileReducer.selected.slice(1));
    } else if (selectedIndex === fileReducer.selected.length - 1) {
      newSelected = newSelected.concat(fileReducer.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        fileReducer.selected.slice(0, selectedIndex),
        fileReducer.selected.slice(selectedIndex + 1)
      );
    }

    dispatch(setSelected(newSelected));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) =>
    fileReducer.selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - fileReducer.files.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(fileReducer.files, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, fileReducer.files]
  );

  const deleteFiles = () => {
    props.deleteSelectedFiles(fileReducer.selected);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {fileReducer.files.length > 0 && (
        <Paper sx={{ width: "100%", mb: 2 }} elevation={6}>
          <EnhancedTableToolbar
            numSelected={fileReducer.selected.length}
            deleteFiles={deleteFiles}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="List of files"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={fileReducer.selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={fileReducer.files.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.fileName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.fileName)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.fileName}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{ fontSize: "0.95rem" }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ fontSize: "0.95rem" }}
                      >
                        {row.fileName}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "0.95rem" }}>
                        {new Date(row.dateCreated).toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 40 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={fileReducer.files.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      {(!fileReducer.files || fileReducer.files.length === 0) && (
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            mt: 4,
            height: "150px",
            display: "grid",
            placeContent: "center",
          }}
          elevation={6}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No Files Uploaded
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default FileListComponent;
