import axios from "axios";


const env = process.env;

export const uploadFileService = (fileData: any) => {
  return axios.post(`${env.REACT_APP_API_URL}/manage_files`, fileData);
};

export const fetchFilesService = () => {
  return axios.get(`${env.REACT_APP_API_URL}/fetch_files`);
};
export const getIndexFilesService = (user_id: string, search_char: string): Promise<any> => {
  return axios.post(`${env.REACT_APP_API_URL}/fetch_files`, {
    user_id, search_char
  });
}
