import { Configuration } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    // clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
    // authority: import.meta.env.VITE_MSAL_AUTHORITY,
    // redirectUri: import.meta.env.VITE_MSAL_REDIRECT,

    // clientId: "b7f8c616-bc16-4eee-a5e7-ef5428fb2804",
    // authority: "https://login.microsoftonline.com/5b2a8fee-4c95-4bdc-8aae-196f8aacb1b6",
    // redirectUri: "https://qa.fetch.sce.com/",

      authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  },
};

export default msalConfig;
