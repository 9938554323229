
import "../home.component.scss";

import * as React from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  QuestionInterface,
  QuestionItemPropsInterface,
} from "../../../interfaces/question.interface";
import { setCopy, unsetCopy, updateQuestion } from "../../../stores/slices/questions.slice";

import { Avatar } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Close } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import HistoryIcon from "@mui/icons-material/History";
import HtmlTooltip from "../../htmlTooltip/htmlTooltip.component";
import { Link } from "@mui/material";
import { LoaderStateType } from "../../../types/common.types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

const QuestionItemComponent = (props: QuestionItemPropsInterface) => {
  const { question, updateRating, sendQuestion } = props;
  //const env = process.env;
  const dispatch = useDispatch();
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [closeDialog, setCloseDialog] = React.useState(false);
  const handledislikeclick = () => {
    setOpenDialog2(true);
    setCloseDialog(false);
  }
  const [isUserTopicSelected, setIsUserTopicSelected] = React.useState(false);
  //const isMobile = window.matchMedia('(max-width: 767px)');
  const [sourceValue, setSourceValue] = React.useState('');
  const [pdfName, setPdfName] = React.useState('');
  const [openDialog3, setOpenDialog3] = React.useState(false);
  const [disablequesbtn, setdisablequesbtn] = React.useState(false);
  const [userTopic, setUserTopic] = React.useState('');
  const [feedback, setFeedback] = React.useState('');
  const [remarks, setRemarks] = React.useState<string[]>([]);
  

  const [clicked, setClicked] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  let name = sessionStorage.getItem('username');
  if (name != undefined || name != null) {
    name = name?.split(' ').map(word => word.charAt(0)).join('');
  }
  const handlepdfclick = (source: string, pageNumber: number, sourceType: string) => {
    if (sourceType == 'news-posts' || sourceType == 'sharepoint-lists') {
      window.open(source, "_blank")
    } else {
      setOpenDialog3(true);
      let srcHeader = decodeURIComponent(source);
      let pageHeader = srcHeader.slice(srcHeader.lastIndexOf('/') + 1);
      setPdfName(pageHeader);
      let url = source.replace("http:", "https:");
      setSourceValue(`${url}#page=${pageNumber}`);
    }
    //dialogPdfContent= `${env.REACT_APP_API_URL}${source}#page=${pageNumber}`;
  }
  // const handledislike=()=>{
  //   setOpenDialog2(false);
  // }
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handlelikeclick = () => {
    setIsDialogOpen(true);
  }
  const [dialogState,] =
    React.useState<LoaderStateType>("success");
  //   const [open, ] = React.useState(false);
  // React.useEffect(() => {
  //   if (!open) {
  //     setReportDescription("");
  //     setQuestionToReport([]);
  //     setReportType("");
  //   }
  // }, [open]);
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserTopicSelected(e.target.value === 'User Topics');
  };
  //   React.useEffect(()=>{
  //   const iframe = document.getElementById('srciFrame') as HTMLIFrameElement;
  //   const addTargetBlankToLinks = () =>{
  //     if(iframe && iframe.contentWindow){
  //     const iframeDocument = iframe.contentWindow.document;
  //     const links = iframeDocument.querySelectorAll('a');
  //     links.forEach(link=>{
  //         link.setAttribute('target','_blank');
  //     });
  //     }
  //   }

  //   if(iframe){
  //     iframe.onload = addTargetBlankToLinks;
  //   }

  // });

  const [currentResponseIndex, setCurrentResponseIndex] =
    React.useState<number>(0);
  const handleButtonClick = () => {
    setClicked(true);
    setdisablequesbtn(true);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

    setInputValue(event.target.value);
  }
  const copyText = (question: QuestionInterface) => {
    const element: HTMLElement | null = document.getElementById(
      `answer-${question.id}`
    );
    if (element) {
      const text: string = element.innerText;
      navigator.clipboard.writeText(text);
      dispatch(setCopy(question));

      setTimeout(() => {
        dispatch(unsetCopy(question));
      }, 3000);
    }
  };
  const handleTopicOnBlur = () => {

    const tempQuestions: QuestionInterface = { ...question, 'userTopic': userTopic };

    // const openPdf = (source: string, pageNumber: number) => {
    //   window.open(
    //     `${env.REACT_APP_API_URL}${source}#page=${pageNumber}`,
    //     "_blank"
    //   );
    // };
    dispatch(updateQuestion(tempQuestions));




  };
  const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setUserTopic(e.target.value);
  };
  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setFeedback(e.target.value);
  };
  const handleFeedbackOnBlur = () => {

    const tempQuestions: QuestionInterface = { ...question, 'feedback': feedback };

    dispatch(updateQuestion(tempQuestions));


  };
  const handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    //setRemarks([...remarks, ...[e.target.value]]);

    const value = e.target.value;
    console.log('sss', value)
    const index = remarks.indexOf(value);
    if (index > -1) {
      setRemarks([...remarks.slice(0, index), ...remarks.slice(index + 1)]);
    } else {
      setRemarks([...remarks, ...[value]]);
    }


  };

  React.useEffect(() => {
    const tempQuestions: QuestionInterface = { ...question, 'remarks': remarks };
    dispatch(updateQuestion(tempQuestions));
  }, [remarks]);

  const renderSources = () => {
    if (!question.sources || question.sources.length === 0) {
      return (
        <ListItem
          key={`source-${new Date().getTime()}`}
          sx={{ fontSize: "15px", textAlign: "left", display: "block" }}
        >
          No Sources Found.
        </ListItem>
      );
    } else {
      const resp: React.ReactElement[] = [];
      const storedSources = sessionStorage.getItem('sources');
      let sources = storedSources ? JSON.parse(storedSources) : [];
      interface Source {
        path: string;
        pageNumber: string;
        sourceType: string;
      }
      let sourceArray: { [key: string]: Source } = {};
  
      for (const source in question.sources) {
        let path = question.sources[source].source;
        let pageNumber = question.sources[source].page.toString();
        let sourceType = question.sources[source].source_type;
        if (sourceArray[path] !== undefined) {
          if (!sourceArray[path].pageNumber.includes(pageNumber)) {
            sourceArray[path].pageNumber += `, Pg. ${pageNumber}`;
          }
        
        } else {
          sourceArray[path] = { path, pageNumber, sourceType }
        }
      }
  
      let count = 0;
      for (const paths in sourceArray) {
        const path = sourceArray[paths].path;
        const sourceType = sourceArray[paths].sourceType;
        const pageNumberArray = sourceArray[path].pageNumber.split(", Pg. ");
        let label = decodeURIComponent(path);
        let index = parseInt(pageNumberArray[0]);
      
        const listItem = (
          <ListItem
            key={`source-${question.id}-${index}`}
            sx={{ fontSize: "15px", paddingTop: "0px", paddingBottom: "0px" }}
            className="text-wrap"
          >
            <span className="col-12 text-wrap list-item" style={{ fontSize: "15px" }}>
              <span style={{ display: "inline-flex" }}>
                <div
                  onClick={() => handlepdfclick(path, index, sourceType)}
                  style={{ fontSize: "inherit", color: "#00A9E0", textDecoration: "none" }}
                >
                  <a
                    href='#'
                    onClick={() => handlepdfclick(path, index, sourceType)}
                    style={{ fontSize: "inherit", color: "#00A9E0", textDecoration: "none" }}
                  >
                    <span style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: label.slice(label.lastIndexOf('/') + 1).charAt(0).toUpperCase() + label.slice(label.lastIndexOf('/') + 1).slice(1) }} />
                  </a>
                </div>
              </span> -
              <span className="ml-1" style={{ fontSize: "15px" }}>
                {sourceType === 'sce.com' || sourceType === 'web-pages' || sourceType === 'news-posts' || sourceType === 'sharepoint-lists' ? null : " Pg."}
                {sourceType === 'sce.com' ? ' sce.com' : sourceType === 'web-pages' ? ' web pages' : sourceType === 'news-posts' ? ' news posts' : sourceType === 'sharepoint-lists' ? 'sharepoint-lists' : sourceArray[path].pageNumber}
              </span>
            </span>
          </ListItem>
        );
      
        if (question.sources.length > 4 && count >= 4) {
          break;
        }
        
        resp.push(listItem);
        count++;
      }
      
  
      for (const paths in sourceArray) {
        const path = sourceArray[paths].path;
        const sourceType = sourceArray[paths].sourceType;
        const pageNumberArray = sourceArray[path].pageNumber.split(", Pg. ");
        const sourceExists = sources.find((storedSource: any) => storedSource.source === path && storedSource.page === sourceArray[path].pageNumber && storedSource.source_type === sourceType && storedSource.id === question.id);
        if (!sourceExists) {
          sources.push({ source: path, page: sourceArray[path].pageNumber, source_type: sourceType, id: question.id });
        }
        
      }
      sessionStorage.setItem('sources', JSON.stringify(sources));
      return resp;
    }
  };
  






  const stringToDate = (dateNumber: number) => {
    const dateString = dateNumber.toString();
    return new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)} ${dateString.slice(8, 10)}:${dateString.slice(10, 12)}:${dateString.slice(12, 14)}`);
  };

  let response = question.response[currentResponseIndex]
   .split("\\n")
    .join("<br/>")
    .replace(/["']+/g, "")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&#39;/g, "")
    .replace(/&#34;/g, "")
    .replace(/\n/g, "<br/>");
    
  // let newUrl = response
  //   .slice(response.indexOf("=") + 1, response.indexOf(">"))
  //   .concat(" ");
  // let qualifiedUrl = "https://www.";
  // let httpsUrl = "https://";

  // if (!newUrl.includes("http")) {
  //   if (!newUrl.includes("www")) {
  //     qualifiedUrl = qualifiedUrl.concat(newUrl);
  //   } else if (newUrl.includes("www")) {
  //     qualifiedUrl = httpsUrl.concat(newUrl);
  //   }
  // } else {
  //   qualifiedUrl = newUrl;
  // }
  // if (response.includes("href")) {
  //   response = response.replace(
  //     response.slice(response.indexOf("=") + 1, response.indexOf(">")),
  //     `${qualifiedUrl} target='_blank'`
  //   );
  // }

  return (
    <div
      //className="questionnew"
      className="mt-2 mb-5"
      key={question.id}
      id={`question-${question.id}`}

    > {isMobile ? null :
      <Avatar

        sx={{
          backgroundColor: "#FBB04C6A",
          //</IconButton> bgcolor: "white",
          color: "#FBB04C",
          width: "30px",
          height: "30px",
          fontSize: "14px",
          fontWeight: "bold",
          //float:"right",
          // left:"800px",
          float: "right",
          right: "-8px",
          top: "19px",
        }}
      >
        {name}
      </Avatar>}

      <Card elevation={3}
        // {...(question.questionSource === "Related Topic" && { style: { border: "2px solid rgba(88, 137, 53)" } })}
        // {...(question.questionSource === "History" && { style: { border: "2px solid rgb(254, 209, 65)" } })}
        className={isMobile ? "mobilenewquestion " : "newquestion "}

      >

        <CardHeader

          sx={{

            backgroundColor: '#D6F2EC',
            opacity: 1,
            p: 2,

            borderRadius: '6px 6px 0px 6px',
            fontSize: '16px',



          }}
          title={
            <Grid container>
              <Grid item xs={10} className="flex-middle-align">

                <HtmlTooltip title={question.question} arrow enterDelay={500}>
                  <span className="question-title-span">
                    {question.question}
                    {question.history && (
                      <Chip
                        className="ms-2 question-title-cip"
                        size="small"
                        icon={<HistoryIcon />}
                        label="History"
                      />
                    )}
                  </span>
                </HtmlTooltip>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{ textAlign: "right", justifyContent: "flex-end" }}
                className="flex-middle-align"
              >
                {question.questionSource === "Related Topic" && (
                  <Chip label={question.questionSource}
                    size="small"
                    sx={{ backgroundColor: "rgb(88, 137, 53)", color: "white", borderRadius: "5px", filter: "drop-shadow(1px 2px 2px rgba(0,0,0,0.4))" }}
                  />
                )}
                {question.questionSource === "History" && (
                  <Chip label={question.questionSource}
                    size="small"
                    sx={{ backgroundColor: "rgb(254, 209, 65)", color: "black", borderRadius: "5px", filter: "drop-shadow(1px 2px 2px rgba(0,0,0,0.4))" }}
                  />
                )}
              </Grid>
            </Grid>
          }
        />

        {/* <CardContent sx={{ backgroundColor: '#E8E8E8', pt: 1, pl: 2, pr: 2 }}> 
          <Grid container spacing={0}>
            <Grid item xs={8} className="flex-middle-align" >
              <Typography
                variant="body1"
                color="text.light"
                gutterBottom
                sx={{  display: "inline-flex", justifyContent: "flex-start" }}
              >
                Response{" "}
                {question.response && question.response.length > 1 && (
                  <>
                    {currentResponseIndex + 1} of&nbsp;
                    {question.response.length}
                  </>
                )}
              </Typography>
            </Grid>

           
            <Grid item xs={12} sx={{ position: "relative" }}>
              <Typography
                component={"div"}
                sx={{
                  pl: 5,
                  pr: 5,
                  pt: 0,
                  pb: 0,
                  minHeight: 50,
                  fontSize: "12px",
                }}
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(
                    question.response[currentResponseIndex]
                  )
                    .split("\\n")
                    .join("<br/>"),
                }}
                id={`answer-${question.id}`}
              ></Typography>
              {question.response && question.response.length > 1 && (
                <div className="row navigation-row">
                  <div className="col-6 text-start previous">
                    <Tooltip
                      title="Previous Response"
                      arrow
                      enterDelay={500}
                      placement="left"
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={currentResponseIndex === 0}
                          onClick={() => {
                            setCurrentResponseIndex(currentResponseIndex - 1);
                          }}
                        >
                         <ChevronLeftIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                  <div className="col-6 text-end next">
                    <Tooltip
                      title="Next Response"
                      arrow
                      enterDelay={500}
                      placement="right"
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={
                            currentResponseIndex >= question.response.length - 1
                          }
                          onClick={() => {
                            setCurrentResponseIndex(currentResponseIndex + 1);
                          }}
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="row question-meta-info">
                <Typography variant="body2" className="col-6 mt-3 ps-5">
                  Generated At:{" "}
                  {question.questionSource && ["History", "Related Topic"].includes(question.questionSource) ? (
                    stringToDate(question.dateCreated).toLocaleString("en-US")) :
                    new Date(question.dateCreated).toLocaleString("en-US")
                  }
                </Typography> */}
        {/* <Typography
                  variant="body2"
                  className="col-6 mt-3 text-end  pe-5"
                >
                  Time Taken:{" "}
                  {parseFloat(question.timeTakenToRespond).toFixed(2)} sec(s)
                </Typography> */}
        {/* </div>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }} className="flex-middle-align">
              <Typography
                variant="body1"
                component={"span"}
                color="text.light"
                className="col-12"
                sx={{ display: "inline-block" }}
              >
                Sources
              </Typography>
            </Grid>
            <List dense className="col-12">{renderSources()}</List>
          </Grid>

           
          <Grid
              item
              xs={4}
             sx={{ textAlign: "right", justifyContent: "flex-end" }}
             
              //className="flex-middle-align"
            >
             
              <Tooltip title="Like" arrow>
                <IconButton
                  size='small'
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  onClick={question.rating !== 2 ? () => { updateRating(question, 2); } : () => { updateRating(question, 0); }}
                  color={question.rating === 2 ? 'success' : 'default'}
                >
                  <ThumbUpIcon sx={{ fontSize: 22 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Dislike" arrow>
                <IconButton
                  size='small'
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                  onClick={question.rating !== 1 ? () => { updateRating(question, 1); } : () => { updateRating(question, 0); }}
                  color={question.rating === 1 ? 'error' : 'default'}
                >
                  <ThumbDownIcon sx={{ fontSize: 22 }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Copy Response" arrow>
                <IconButton
                  aria-label="settings"
                  onClick={() => copyText(question)}
                  size='small'
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  {!question.copied && <ContentCopyIcon sx={{ fontSize: 18 }} />}
                  {question.copied && (
                    <CheckCircleOutlineIcon color="success" sx={{ fontSize: 18 }} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>

        </CardContent> */}


        {/* <CardActions>
        </CardActions> */}
      </Card>

      <div className="row col-12">
        <div
          className="mt-3 mb-2"
          key={question.id}
          id={`question-${question.question_id}`}
        >


          <Card elevation={3}
            // {...(question.questionSource === "Related Topic" && { style: { border: "2px solid rgb(88, 137, 53)" } })}
            // {...(question.questionSource === "History" && { style: { border: "2px solid rgb(254, 209, 65)" } })}
            className={isMobile ? "mobilenewanswer" : "newanswer"}
          >



            <CardContent sx={{ backgroundColor: '#FFFFFF', p: 2, fontSize: "1rem !important", borderRadius: "6px 6px 6px 0px" }}>
              <Grid container spacing={0}>
                <Grid item xs={8} className="flex-middle-align" >
                  <Typography
                    className="respFont"
                    variant="body1"
                    color="text.light"
                    gutterBottom
                    sx={{ display: "inline-flex", justifyContent: "flex-start", fontSize: "1rem !important", minWidth: "100%" }}
                  >

                    {question.response && question.response.length > 1 && (
                      <>
                        {currentResponseIndex + 1} of&nbsp;
                        {question.response.length}
                      </>
                    )}
                  </Typography>
                </Grid>
               {question.is_answer_in_bullet_points ? (
                  <div className="reponse"  id={`answer-${question.id}`}>
                    {/* Split the response into lines */}
                    {response.split("<br/>").map((line, index) => (
                      <div className="reponse" key={index} style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                        {/* Check if the line starts with a number followed by a period */}
                        {/^\s*\d+\.\s*/.test(line) || /^\s*•\s*/.test(line) || /^\s*\[a-zA-Z]+\.\s*/.test(line) ? (
                          <div className="reponse" style={{ display: 'flex', alignItems: 'flex-start',wordBreak:'keep-all' }}>
                            <span className="reponse" style={{ paddingLeft: '40px', marginRight: '10px' }}>{line.substring(0, line.indexOf('.') + 1)}</span>
                            <div className="reponse" dangerouslySetInnerHTML={{ __html: line.replace(/^\s*\d+\.\s*/, '').replace(/^\s*•\s*/, '') }} />
                          </div>
                        ) : /^\s*-/.test(line) ? (
                          <div className="reponse" style={{ display: 'flex', alignItems: 'flex-start', wordBreak: "keep-all", marginLeft: '50px' }}>
                            <span className="reponse" style={{ marginRight: '10px' }}>•</span>
                            <div className="reponse" dangerouslySetInnerHTML={{ __html: line.replace(/^\s*-/, '') }} />
                          </div>
                        ) : /^\s*[a-zA-Z]\./.test(line) ? (
                          <div className="reponse" style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '45px', wordBreak: "keep-all"}}>
                            <span className="reponse" style={{ marginRight: '10px' }}>{line.match(/^\s*[a-zA-Z]\./)}</span>
                            <div className="reponse" dangerouslySetInnerHTML={{ __html: line.replace(/^\s*[a-zA-Z]\./, '') }} />
                          </div>) :
                          /^\s*(\\n)?\*\s*/.test(line) ? (
                            <div className="reponse" style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '45px', wordBreak: "keep-all" }}>
                              <span className="reponse" style={{ marginRight: '10px' }}>•</span>
                              <div className="reponse" dangerouslySetInnerHTML={{ __html: line.replace(/^\s*(\\n)?\*\s*/, '') }} />
                            </div>
                          ) : (
                            <div className="reponse" style={{ display: 'flex', alignItems: 'flex-start' }}>
                              <div className="reponse" style={{ marginLeft: /^\s*\d+\./.test(line) ? "40px" : "0px" }} dangerouslySetInnerHTML={{ __html: line }} />
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="reponse" dangerouslySetInnerHTML={{ __html: response }} id={`answer-${question.id}`} />
                )}

                {/* <Grid item xs={12} sx={{ position: "relative", }}>
                  {isMobile ?
                    <Typography
                      component={"div"}
                      sx={{
                        pl: 0,
                        pr: 0,
                        pt: 0,
                        pb: 0,
                        minHeight: 50,
                        fontSize: "12px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                          response
                        ).replace(/["']+/g, ""),
                      }}
                      id={`answer-${question.id}`}
                    ></Typography> :
                    <Typography
                      component={"div"}
                      sx={{
                        pl: 0,
                        pr: 0,
                        pt: 0,
                        pb: 0,
                        minHeight: 50,
                        fontSize: "1rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                          response
                        ).replace(/["']+/g, ""),
                      }}
                      id={`answer-${question.id}`}
                    ></Typography>}

                  {question.response && question.response.length > 1 && (
                    <div className="row navigation-row">
                      <div className="col-6 text-start previous">
                        <Tooltip
                          title="Previous Response"
                          arrow
                          enterDelay={500}
                          placement="left"
                        >
                          <span>
                            <IconButton
                              size="small"
                              disabled={currentResponseIndex === 0}
                              onClick={() => {
                                setCurrentResponseIndex(currentResponseIndex - 1);
                              }}
                            >
                              <ChevronLeftIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-6 text-end next">
                        <Tooltip
                          title="Next Response"
                          arrow
                          enterDelay={500}
                          placement="right"
                        >
                          <span>
                            <IconButton
                              size="small"
                              disabled={
                                currentResponseIndex >= question.response.length - 1
                              }
                              onClick={() => {
                                setCurrentResponseIndex(currentResponseIndex + 1);
                              }}
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Grid> */}
                <Grid item xs={12}>
                  {/* <div className="row question-meta-info">
                <Typography variant="body2" className="col-6 mt-3 ps-5">
                  Generated At:{" "}
                  {question.questionSource && ["History", "Related Topic"].includes(question.questionSource) ? (
                    stringToDate(question.dateCreated).toLocaleString("en-US")) :
                    new Date(question.dateCreated).toLocaleString("en-US")
                  }
                </Typography>
                <Typography
                  variant="body2"
                  className="col-6 mt-3 text-end  pe-5"
                >
                  Time Taken:{" "}
                  {parseFloat(question.timeTakenToRespond).toFixed(2)} sec(s)
                </Typography>
              </div> */}
                </Grid>
                <Grid item xs={12} sx={{ mt: 3, }} className="flex-middle-align">
                  <Typography
                    variant="body1"
                    component={"span"}
                    color="text.light"
                    className="col-12"
                    sx={{ display: "inline-block", fontWeight: 'bold', fontSize: '15px' }}
                  >
                    Source

                  </Typography>
                </Grid>
                <List dense className="col-12">{renderSources()}</List>

              </Grid>


              <Grid
                item
                xs={4}
                sx={{ textAlign: "right", justifyContent: "flex-end" }}

              //className="flex-middle-align"
              >


                <Tooltip title="Like" arrow>
                  <IconButton
                    className={question.rating === 1 ? "pop-effect-like" : ""}
                    size='small'
                    sx={{ paddingLeft: 1, paddingRight: 1 }}
                    // onClick={question.rating !== 2 ? () => { updateRating(question, 2); } : () => { updateRating(question, 0); }}
                    disabled={question.rating === 2 ? true : false}
                    onClick={question.rating === 1 ? () => { updateRating(question, 0) } : handlelikeclick}
                    color={question.rating === 1 ? 'success' : 'default'}
                  >
                    <ThumbUpIcon sx={{ fontSize: 22 }} />
                  </IconButton>
                </Tooltip>

                <Dialog open={openDialog3} onClose={() => setOpenDialog3(false)} maxWidth="md"
                  PaperProps={{
                    sx: {

                      width: "100%",
                      height: "100%"
                    },
                  }}>

                  <DialogTitle><div className='mobilePdfTextHeading'><label ><b style={{ fontSize: 18 }}>{pdfName}</b></label></div>  <div className='pdfClose'><Button onClick={() => setOpenDialog3(false)}><Close sx={{ color: "black" }} /></Button></div> </DialogTitle>

                  {dialogState === "loading" && (
                    <DialogContent sx={{ height: 300 }}>
                      <div className="col-12 dialog-loader-container">
                        <CircularProgress color="secondary" />
                      </div>
                    </DialogContent>
                  )}
                  {(dialogState === "success" || dialogState === "error") && (
                    <>
                      <DialogContent style={{ height: 225 }}>
                        {/* </><iframe src={sourceValue}  scrolling="no" frameborder="no" overflow="hidden" style={{height:'500px' width:'500px'}}/> */}
                        <iframe title={"modal"} id='srciFrame' src={sourceValue} style={{ width: '100%', height: '100vh' }} />

                      </DialogContent>
                      <DialogActions sx={{ mb: 3, mr: 2 }}>
                        <Link href={sourceValue} target="_blank" color="#0F47C6" underline="none">Open document in a new tab </Link> <OpenInNewIcon sx={{ color: "#0F47C6" }} />
                      </DialogActions>
                    </>
                  )}

                </Dialog>

                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md"
                  PaperProps={{
                    sx: {

                      width: "431px",
                      height: "302px"
                    },
                  }}>
                  <DialogTitle >{<b style={{ fontSize: "18px" }}>Thank you for your feedback!</b>}<Button onClick={() => setIsDialogOpen(false)}><Close sx={{ color: "black", marginLeft: "370%" }} /></Button>  </DialogTitle>
                  {dialogState === "loading" && (
                    <DialogContent sx={{ height: 300 }}>
                      <div className="col-12 dialog-loader-container">
                        <CircularProgress color="secondary" />
                      </div>
                    </DialogContent>
                  )}
                  {(dialogState === "success" || dialogState === "error") && (
                    <>
                      <DialogContent style={{ height: 225 }}>
                        {/* <DialogContentText fontFamily ="Segoe UI" fontSize="14px" color="#19191D">  */}
                        {<div>
                          <div className="btt"> <InputLabel id="report-type-select-label">
                            Would you like to save this response in your Saved<br />
                            Searches?
                          </InputLabel></div><div className="btts">
                            <label><input type="radio" value="Default Topics" name="userinput" defaultChecked={!isUserTopicSelected} onChange={handleRadioChange} /> Default Topics</label></div>
                          <div className="btts">
                            <label><input type="radio" value="User Topics" name="userinput" defaultChecked={isUserTopicSelected} onChange={handleRadioChange} /> User Topics</label></div>
                          <div className="feedback">Topic Name</div><input type="text" id="txtTopic" onChange={handleTopicChange} onBlur={handleTopicOnBlur} disabled={!isUserTopicSelected} />



                        </div>}
                        {/* </DialogContentText> */}
                      </DialogContent>
                      <DialogActions sx={{ mb: 3, mr: 2 }}>
                        <Button variant="contained" sx={{ background: "#FFFFFF", color: "#19191D" }} onClick={() => setIsDialogOpen(false)}>Close</Button>
                        <Button
                          onClick={question.rating !== 1 ? () => { updateRating(question, 1); setIsDialogOpen(false); setCloseDialog(false) } : () => { updateRating(question, 0); setIsDialogOpen(false) }}


                          variant="contained"
                          sx={{ background: "#00664F", color: "#FFFFFF" }}
                          id="savelike"
                        >
                          Save
                        </Button>


                      </DialogActions>
                    </>
                  )}
                </Dialog>

                <Tooltip title="Dislike" arrow>
                  <IconButton
                    className={question.rating === 2 ? "pop-effect pop-effect-dislike" : "pop-effect"}
                    size='small'
                    sx={{ paddingLeft: 1, paddingRight: 1 }}
                    disabled={question.rating === 2 || question.rating === 1 ? true : false}
                    onClick={question.rating === 2 ? () => { updateRating(question, 0); setRemarks([]) } : handledislikeclick}
                    // onClick={question.rating !== 1 ? () => { updateRating(question, 1); } : () => { updateRating(question, 0); }}
                    color={question.rating === 2 || closeDialog ? 'error' : 'default'}
                  >
                    <ThumbDownIcon sx={{ fontSize: 22 }} />
                  </IconButton>
                </Tooltip>

                <Dialog open={openDialog2} onClose={() => { setOpenDialog2(false); if (question.rating === 1) { setCloseDialog(false) } else { updateRating(question, 2); setCloseDialog(true) } }} maxWidth="md"
                  PaperProps={{
                    sx: {

                      width: "449px",
                      height: "416px"
                    },
                  }}>

                  <DialogTitle>{<b style={{ fontSize: '18px' }}>Provide Feedback</b>} <Button onClick={() => setOpenDialog2(false)}
                  ><Close sx={{ color: "black", marginLeft: "850%" }} /></Button></DialogTitle>

                  {dialogState === "loading" && (
                    <DialogContent sx={{ height: 300 }}>
                      <div className="col-12 dialog-loader-container">
                        <CircularProgress color="secondary" />
                      </div>
                    </DialogContent>
                  )}
                  {(dialogState === "success" || dialogState === "error") && (
                    <>
                      <DialogContent style={{ height: 225 }}>
                        {/* <DialogContentText fontFamily ="Segoe UI" color="#19191D" fontSize="18px"> */}
                        {<div>
                          <div className="btt">
                            <InputLabel id="report-type-select-label">
                              <span className="blike">Thank you for your feedback!<br /> Please share some details as to why you disliked this response. </span>
                            </InputLabel></div>

                          <div className="vagueResponse"><label><input type="checkbox" onChange={handleRemarksChange} value="Inaccurate Information" name="userinput" /> Inaccurate Information</label></div>
                          <div><label><input type="checkbox" onChange={handleRemarksChange} value="Confusing Response" name="userinput" /> Confusing Response</label></div>
                          <div><label><input type="checkbox" onChange={handleRemarksChange} value="Irrelevant Content" name="userinput" /> Irrelevant Content</label></div>
                          <div><label><input type="checkbox" onChange={handleRemarksChange} value="Incomplete/Vague Answer" name="userinput" /> Incomplete/Vague Answer</label></div>
                          <div><label><input type="checkbox" onChange={handleRemarksChange} value="Incorrect Source Reference" name="userinput" /> Incorrect Source Reference</label></div>
                          <div><label><input type="checkbox" onChange={handleRemarksChange} value="Other" name="userinput" /> Other</label></div>

                          <div className="feedbackDislike"><label >Feedback</label></div>
                          <textarea className="feedbackTextArea" onBlur={handleFeedbackOnBlur} onChange={handleFeedbackChange} id="myTextArea" rows={4} cols={60}>
                          </textarea>


                        </div>}
                        {/* </DialogContentText> */}
                      </DialogContent>
                      <DialogActions sx={{ mb: 3, mr: 2 }}>
                        <Button variant="contained" sx={{ background: "#FFFFFF", color: "#19191D" }} onClick={() => setOpenDialog2(false)}>Close</Button>
                        <Button
                          onClick={question.rating !== 2 ? () => { updateRating(question, 2); setOpenDialog2(false) } : () => { updateRating(question, 0); setOpenDialog2(false) }}
                          variant="contained"
                          sx={{ background: "#00664F", color: "#FFFFFF" }}
                        >
                          Save
                        </Button>


                      </DialogActions>
                    </>
                  )}
                </Dialog>




                <IconButton
                  aria-label="settings"
                  onClick={() => copyText(question)}
                  size='small'
                  sx={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  {!question.copied && (
                    <Tooltip title="Copy Response" arrow>
                      <ContentCopyIcon sx={{ fontSize: 18 }} /></Tooltip>)}

                  {question.copied && (
                    <Tooltip title="Copied!" arrow>
                      <CheckCircleOutlineIcon color="success" sx={{ fontSize: 18 }} />
                    </Tooltip>
                  )}
                </IconButton>

              </Grid>


            </CardContent>
            {/* <CardActions>
        </CardActions> */}

          </Card>

          {question.rating === 2 && question.rateresponse.status == "Success" && (
            <>
              <div className="row col-9">
                <div key={question.question_id} id={`ques-${question.question_id}`}>
                  <Card elevation={4}
                    className={isMobile ? "mobilenewanswer" : "newanswer1"}>
                    <CardContent sx={{
                      backgroundColor: '#FFFFFF', p: 2, fontSize: "16px", borderRadius: "6px 6px 6px 0px"
                    }}>
                      <div className="row">
                        <span><b style={{ fontSize: '16px' }}>Thank you for your feedback!</b></span>
                        <span style={{ fontSize: '16px' }}>We're sorry we couldn't provide the answer we were looking for,
                          feel free to ask another question, or choose from the options below. </span>
                      </div>
                      <div className="flex-container" style={{ display: "flex" }}>
                        {question.rateresponse.generated_questions ? question.rateresponse.generated_questions.map((data, index) => {
                          return (
                            <div key={index}>
                              {clicked ? (

                                <div
                                  contentEditable

                                  style={{
                                    background: "#ffffff",
                                    borderColor: "#898989",
                                    fontWeight: "400",
                                    padding: "10px",
                                    fontSize: "16px",
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    color: "#898989",

                                    borderWidth: "1px",
                                    outline: "auto",

                                    borderRadius: "5px",
                                  }}
                                  className="text-wrap"
                                  title={data}
                                  dangerouslySetInnerHTML={{ __html: data }}
                                ></div>
                              ) : (

                                <Button
                                  onClick={(event) => {
                                    handleButtonClick();
                                    sendQuestion(event);
                                  }}
                                  value={data}
                                  id={question.rateresponse.parent_question_id}
                                  variant="contained"
                                  sx={{
                                    background: "#ffffff",
                                    textTransform: 'none !important',
                                    borderColor: '#00664F',
                                    fontWeight: '400 !important',
                                    padding: '4px',
                                    fontSize: "16px",
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    color: "#00664F",
                                    border: 'revert-layer',
                                    borderWidth: '1px',
                                    pointerEvents: disablequesbtn ? 'none' : 'auto'
                                  }}
                                >
                                  {data}
                                </Button>
                              )}
                            </div>
                          );
                        }) : ''}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>

              {/* <div className="row">
            {isMobile ? null : <Avatar sx={{
              backgroundColor: "#ffffff", //</IconButton> bgcolor: "white",
              color: "#e00000",
              width: "30px",
              height: "30px",
              fontSize: "14px",
              fontWeight: "bold",
              // top:"385px",
              marginBottom: "1px",
              right: "28px",
              marginTop: "-36px"
            }}
            >
              <SmartToyIcon />
            </Avatar>}
          </div> */}
            </>)}
          {question.rating === 2 && question.rateresponse.status == "Failed" && (
            <>
              <div className="row col-9">
                <div key={question.question_id} id={`ques-${question.question_id}`}>
                  <Card elevation={4}


                    className={isMobile ? "mobilenewanswer" : "newanswer1"}>
                    <CardContent sx={{
                      backgroundColor: '#FFFFFF', p: 2, fontSize: "16px", borderRadius: "6px 6px 6px 0px"
                    }}>
                      <div className="row">
                        <span><b style={{ fontSize: '16px' }}>Thank you for your feedback!</b></span>
                        <span style={{ fontSize: '16px' }}>We're sorry we couldn't provide the answer you were
                          looking for, could you please try rewording the question. </span>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>

              {/* <div className="row">
        {isMobile ? null : <Avatar sx={{
          backgroundColor: "#ffffff", //</IconButton> bgcolor: "white",
          color: "#e00000",
          width: "30px",
          height: "30px",
          fontSize: "14px",
          fontWeight: "bold",
          // top:"385px",
          marginBottom: "1px",
          right: "28px",
          marginTop: "-36px"
        }}
        >
          <SmartToyIcon />
        </Avatar>}
      </div> */}
            </>)
          }

        </div >

      </div>

      <div className="row">
        {isMobile ? null : <Avatar
          sx={{
            backgroundColor: "#FFFFFF",
            //</IconButton> bgcolor: "white",
            color: question.rating === 2 ? "#CB3535" : "#247B68",
            width: "30px",
            height: "30px",
            fontSize: "14px",
            fontWeight: "bold",
            // top:"385px",
            marginBottom: "1px",
            right: "28px",
            marginTop: "-36px"
          }}
        >
          <SmartToyIcon />
        </Avatar>}
      </div>
      {question.follow_up_questions && question.rating != 2  ? (
        <div>



          <div className="flex-container" style={{ display: disablequesbtn ? 'none' : 'flex' }}>
            {question.follow_up_questions ? question.follow_up_questions.map((data, index) => {
              return (
                <div key={index}>
                  {clicked ? (

                    <div
                      contentEditable

                      style={{
                        background: "#ffffff",
                        borderColor: "#898989",
                        fontWeight: "400",
                        padding: "10px",
                        fontSize: "16px",
                        marginTop: "10px",
                        marginRight: "10px",
                        color: "#898989",

                        borderWidth: "1px",
                        outline: "auto",

                        borderRadius: "5px",
                      }}
                      className="text-wrap"
                      title={data}
                      dangerouslySetInnerHTML={{ __html: data }}
                    ></div>
                  ) : (

                    <Button
                      onClick={(event) => {
                        handleButtonClick();
                        sendQuestion(event);

                      }}
                      value={data}

                      variant="contained"
                      sx={{
                        background: "#ffffff",
                        textTransform: 'none !important',
                        borderColor: '#00664F',
                        fontWeight: '400 !important',
                        padding: '4px',
                        fontSize: "16px",
                        marginTop: "10px",
                        marginRight: "10px",
                        color: "#00664F",
                        border: 'revert-layer',
                        borderWidth: '1px',
                        borderRadius: '10px',
                        height: '-webkit-fill-available'

                        // pointerEvents: disablequesbtn ? 'none' : 'auto'
                      }}
                    >
                      {data}
                    </Button>
                  )}
                </div>
              );
            }) : ''}
          </div>

        </div>
      ) : ""
      }
    </div >

  );
};

export default QuestionItemComponent;

