import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface CustomSnackBarProps {
  openSnackbar: boolean;
  message: string;
  type: string;
  handleSnackBarClose: any;
  isDrawerOpen2:boolean;
}
const CustomSnackbar = (props: CustomSnackBarProps  ) => {
  const { openSnackbar, message, type, handleSnackBarClose,isDrawerOpen2 } = props;

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
      
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleSnackBarClose}
        anchorOrigin={isDrawerOpen2?{ vertical: "top", horizontal: "center"  }:{vertical: "top", horizontal: "right"}}
        sx={{ mt: 7 }}
        style={isDrawerOpen2?{marginTop:"49px",marginLeft:"18%"}: {marginRight:'3%',marginTop:"49px"}}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={
            type === "error"
              ? "error"
              : type === "warning"
                ? "warning"
                : type === "info"
                  ? "info"
                  : "success"
          }
          sx={{ width: "100%" }}
          data-testid="CustomAlert" 
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackbar;
