import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, colors } from "@mui/material";
import "./alert.component.scss";
const AlertCompoent = () => {
  const [open, setOpen] = React.useState(true);
  return (
    <Box
      sx={{
        width: "100%",
        display: "block",
        position: "absolute",
        top: 62,
      }}
    >
    <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" sx={{ color: colors.grey[50] }} />
            </IconButton>
          }
          sx={{
            mb: 2,
            bgcolor: colors.red[900],
            color: "white !important",
            borderRadius: 0,
          }}
          severity="error"
        >
          <Typography variant="body1">
            Do not upload or delete any file if you are not authorised!
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};
export default AlertCompoent;
