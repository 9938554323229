// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-loader-container {
  display: grid;
  place-content: center;
  height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components/reportDialog/reportDialog.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,aAAA;AACJ","sourcesContent":[".dialog-loader-container {\r\n    display: grid;\r\n    place-content: center;\r\n    height: 250px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
