import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface CustomAlertProps extends AlertProps {
  startIcon?: React.ReactNode;
}

const CustomAlert = React.forwardRef<HTMLDivElement, CustomAlertProps>(
  function CustomAlert(props, ref) {
    const { startIcon, ...other } = props;
    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="filled"
        icon={
          startIcon ? (
            <span style={{ marginRight: "8px" }}>{startIcon}</span>
          ) : null
        }
        {...other}
      />
    );
  }
);

interface CustomSnackBarProps {
  openSnackbar: boolean;
  message: string;
  type: string;
  handleSnackBarClose: any;
}

const CustomSnackbarNew = (props: CustomSnackBarProps) => {
  const { openSnackbar, message, type, handleSnackBarClose } = props;

  const getSeverity = (): AlertColor => {
    switch (type) {
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "info":
        return "info";
      case "success":
        return "success";
      default:
        return "success";
    }
  };

  const getStartIcon = () => {
    if (type === "error") {
      return <CheckCircleRoundedIcon style={{ color: "#D32F2F" }} />;
    }
    return <CheckCircleRoundedIcon style={{ color: "#78BE20" }} />;
  };

  const getBorderLeftColor = () => {
    if (type === "error") {
      return "5px solid #D32F2F";
    }
    return "5px solid #78BE20";
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ mt: 7 }}
        style={{ left: '67%' }}
      >
        <CustomAlert
          onClose={handleSnackBarClose}
          severity={getSeverity()}
          startIcon={getStartIcon()}
          sx={{ width: "100%", color: "#19191D", backgroundColor: "#FFFFFF", borderLeft: getBorderLeftColor() }}
        >
          {message}
        </CustomAlert>
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackbarNew;
