import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BugReportIcon from "@mui/icons-material/BugReport";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  ReportPayloadInterface,
  ReportTypeInterface,
} from "../../interfaces/report.interface";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Chip,
  Box,
  CircularProgress,
} from "@mui/material";
import { RootState } from "../../stores/store";
import { useSelector } from "react-redux";
import { QuestionInterface } from "../../interfaces/question.interface";
import CustomSnackbar from "../customSnackBar/customSnackBar.component";
import { LoaderStateType } from "../../types/common.types";
import { sendReport as sendReportService } from "../../services/report.service";
import "./reportDialog.component.scss";
import axios from "axios";

const ReportDialogComponent = () => {
  const env = process.env;
  const [open, setOpen] = React.useState(false);
  const [reportType, setReportType] = React.useState("");
  const [reportDescription, setReportDescription] = React.useState("");
  const [questionToReport, setQuestionToReport] = React.useState<string[]>([]);
  const questionReducer = useSelector((state: RootState) => state.question);
  const [snackbarType, updateSnackbarType] = React.useState<string>("");
  const [snackbarMessage, updateSnackbarMessage] = React.useState<string>("");
  const [openSnackbar, updateOpenSnackbar] = React.useState<boolean>(false);
  const [dialogState, updateDialogState] =
    React.useState<LoaderStateType>("success");

  React.useEffect(() => {
    if (!open) {
      setReportDescription("");
      setQuestionToReport([]);
      setReportType("");
    }
  }, [open]);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const historyReducer = useSelector((state: RootState) => state.history);
  const reportTypeArray: ReportTypeInterface[] = [
    {
      id: "report-1",
      message: "Data Source is inaccurate",
    },
    {
      id: "report-2",
      message: "Response is inaccurate",
    },
    {
      id: "report-3",
      message: "Both are inaccurate",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleGenerateSummary = async () => {
    try {
      setTitle("Generating Summary...");
      setIsDialogOpen(true);
      updateDialogState("loading");

      const summaryPayload = historyReducer.questions.map((question: QuestionInterface) => {
        return {
          "question": question.question,
          "answer": question.response
        }
      });

      const response = await axios.post(
        `${env.REACT_APP_API_URL}/create_summary`,
        summaryPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      const summaryText = data.summary;
      const titleText = data.title;
      setTitle(titleText);
      setSummary(summaryText);
      updateDialogState("success");
    } catch (error) {
      console.error(error);
      updateDialogState("error");
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleReportTypeChange = (event: SelectChangeEvent) => {
    setReportType(event.target.value);
  };

  const handleQuestionToReportChange = (
    event: SelectChangeEvent<typeof questionToReport>
  ) => {
    const {
      target: { value },
    } = event;
    setQuestionToReport(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleReportDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportDescription(event.target.value);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log(event);
    if (reason === "clickway") {
      return;
    }

    updateOpenSnackbar(false);
  };

  const sendReport = () => {
    updateDialogState("loading");

    const questions: QuestionInterface[] = [];
    questionToReport.forEach((questionString: string) => {
      questionReducer.questions.forEach((question: QuestionInterface) => {
        if (question.question === questionString) {
          questions.push(question);
        }
      });
    });

    const servicePayload: ReportPayloadInterface = {
      description: reportDescription,
      reportType,
      questions,
    };

    console.log(servicePayload);

    sendReportService(servicePayload)
      .then((res) => {
        updateSnackbarMessage("Report sent successfully.");
        updateSnackbarType("success");
        updateOpenSnackbar(true);
        updateDialogState("success");
        handleClose();
      })
      .catch((err) => {
        updateSnackbarMessage("Unable to send the report.");
        updateSnackbarType("error");
        updateOpenSnackbar(true);
        updateDialogState("success");
      });
  };

  return (
    <div>
{/*       <Button
     
        color="inherit"
        variant="text"
        startIcon={<SummarizeIcon />}
        sx={{ mr: 1 , border: '2px solid #F7D35E',}}
        size="medium"
        onClick={handleGenerateSummary}
      >
        <span className="button-text">Generate Summary</span>
      </Button> */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md"
        PaperProps={{
          sx: {
            width: "100%"
          },
        }}>
        <DialogTitle>{title}</DialogTitle>
        {dialogState === "loading" && (
          <DialogContent sx={{ height: 300 }}>
            <div className="col-12 dialog-loader-container">
              <CircularProgress color="secondary" />
            </div>
          </DialogContent>
        )}
        {(dialogState === "success" || dialogState === "error") && (
          <>
            <DialogContent style={{ height: 225 }}>
              <DialogContentText fontSize="18px">{summary}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mb: 3, mr: 2 }}>
              <Button
                onClick={() => setIsDialogOpen(false)}
                color="inherit"
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* <Button
        color="secondary"
        variant="text"
        startIcon={<BugReportIcon />}
        sx={{ mr: 1 }}
        size="medium"
        onClick={handleClickOpen}
      >
        <span className="button-text">Report Error</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        <DialogTitle>Report Error</DialogTitle>
        {(dialogState === "success" || dialogState === "error") && (
          <DialogContent>
            <div>
              <DialogContentText>
                Please enter few more details regarding the error.
              </DialogContentText>

              <FormControl fullWidth sx={{ mt: 3 }} required>
                <InputLabel id="report-type-select-label">
                  Report Type
                </InputLabel>
                <Select
                  labelId="report-type-select-label"
                  id="report-type-select"
                  value={reportType}
                  label="Report Type"
                  onChange={handleReportTypeChange}
                >
                  {reportTypeArray.map((report: ReportTypeInterface) => (
                    <MenuItem value={report.message} key={report.id}>
                      {report.message}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 3 }} required>
                <InputLabel id="question-to-report-select-label">
                  Question to Report
                </InputLabel>
                <Select
                  labelId="question-to-report-select-label"
                  id="question-to-report-select"
                  multiple
                  value={questionToReport}
                  label="Question to Report"
                  onChange={handleQuestionToReportChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Tooltip title={value} enterDelay={500}>
                          <Chip
                            key={value}
                            label={
                              value.length > 30
                                ? `${value.slice(0, 30)}...`
                                : value
                            }
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  )}
                >
                  {questionReducer.questions &&
                    questionReducer.questions.length > 0 &&
                    questionReducer.questions.map(
                      (question: QuestionInterface) => (
                        <MenuItem
                          value={question.question}
                          key={`question-to-report-${question.id}`}
                        >
                          <Tooltip
                            title={question.question}
                            arrow
                            placement="right"
                            enterDelay={1000}
                          >
                            <span>
                              {question.question.length > 50
                                ? `${question.question.slice(0, 50)}...`
                                : question.question}
                            </span>
                          </Tooltip>
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
              <TextField
                id="report-description"
                label="Report Description"
                variant="outlined"
                required
                value={reportDescription}
                onChange={handleReportDescriptionChange}
                fullWidth
                autoComplete="off"
                rows={4}
                sx={{
                  mt: 3,
                }}
              />
            </div>
          </DialogContent>
        )}
        {dialogState === "loading" && (
          <DialogContent sx={{ height: 300 }}>
            <div className="col-12 dialog-loader-container">
              <CircularProgress color="secondary" />
            </div>
          </DialogContent>
        )}
        <DialogActions sx={{ mb: 3, mr: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="contained"
            disabled={dialogState === "loading"}
          >
            Cancel
          </Button>
          <Button
            onClick={sendReport}
            color="secondary"
            variant="contained"
            disabled={
              !reportType ||
              questionToReport.length === 0 ||
              !reportDescription ||
              dialogState === "loading"
            }
          >
            Send Report
          </Button>
        </DialogActions>
      </Dialog> */}
      <CustomSnackbar
          isDrawerOpen2={true}
        openSnackbar={openSnackbar}
        type={snackbarType}
        message={snackbarMessage}
        handleSnackBarClose={handleSnackbarClose}
      />
    </div>
  );
};

export default ReportDialogComponent;
