import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  QuestionInterface,
  QuestionRatingType,
  QuestionSliceInterface,
  RateResponse,
} from "../../interfaces/question.interface";

// import { mockQuestions } from "../../data/questionSection.data";

const initialState: QuestionSliceInterface = {
  questions: [],
 
};

export const QuestionSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {
    addNewQuestion: (state, action: PayloadAction<QuestionInterface[]>) => {
      state.questions = [...state.questions, ...action.payload];
    },
    updateQuestion: (state, action: PayloadAction<QuestionInterface>) => {
      const questionToUpdate = state.questions.filter(
        (question: QuestionInterface) => question.id === action.payload.id);
        questionToUpdate[0].userTopic = action.payload.userTopic;
        questionToUpdate[0].remarks = action.payload.remarks;
        questionToUpdate[0].feedback = action.payload.feedback;

      // const remainingQuestions = state.questions.filter(
      //     (question: QuestionInterface) => question.id !== action.payload.id);

      // state.questions = [...remainingQuestions, ...questionToUpdate];

      // console.log('updated questions:', state.questions);
    },
    setCopy: (state, action: PayloadAction<QuestionInterface>) => {
      const tempQuestions = state.questions.map(
        (question: QuestionInterface) => {
          if (question.id === action.payload.id) {
            question.copied = true;
          }
          return question;
        }
      );
      state.questions = [...tempQuestions];
    },
    unsetCopy: (state, action: PayloadAction<QuestionInterface>) => {
      const tempQuestions = state.questions.map(
        (question: QuestionInterface) => {
          if (question.id === action.payload.id) {
            question.copied = false;
          }
          return question;
        }
      );
      state.questions = [...tempQuestions];
    },
    clearState: (state) => {
      state.questions = [];
    },
    updateRating: (
      state,
      action: PayloadAction<{
        question: QuestionInterface;
        rating: QuestionRatingType;
      }>
    ) => {
      const { question, rating } = action.payload;
      const tempQuestions = state.questions.map((ques: QuestionInterface) => {
        if (ques.id === question.id) {
          ques.rating = rating;
        }
        return ques;
      });
      state.questions = [...tempQuestions];
    },
    updaterateresponse: (
      state,
      action: PayloadAction<{
        question: QuestionInterface;
        rateresponse: RateResponse;
      }>) => {
        const { question, rateresponse } = action.payload;
        const tempQuestions = state.questions.map((ques: QuestionInterface) => {
          if (ques.id === question.id) {
            ques.rateresponse = rateresponse;
          }
          return ques;
        });
        state.questions = [...tempQuestions];
      }
  },
});

export const { addNewQuestion, updateQuestion, setCopy, unsetCopy, clearState, updateRating,updaterateresponse} =
  QuestionSlice.actions;
export default QuestionSlice.reducer;
