import { HashRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import HomeComponent from "../components/home/home.component";
import NavigationComponent from "../components/navigation/navigation.component";
import ManageFilesComponent from "../components/manageFiles/manageFiles.component";
import DashboardComponent from "../components/Dashboard/dashboard.component";
const customRouter = () => {
  return (
    <BrowserRouter>
      <NavigationComponent />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/admin/manage_files" element={<ManageFilesComponent />} />
        <Route path="/dashboard" element={<DashboardComponent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default customRouter;
