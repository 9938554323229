import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../stores/store";
import { useSelector } from "react-redux";
import { QuestionInterface } from "../../interfaces/question.interface";
import { HistoryDrawerInterface } from "../../interfaces/history.interface";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryIcon from "@mui/icons-material/History";
import "./historyDrawer.component.scss";
import CustomSnackbar from "../customSnackBar/customSnackBar.component";
import LoaderComponent from "../loader/loader.component";
import { LoaderStateType } from "../../types/common.types";
import { Avatar } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { Button } from "react-bootstrap";
import { getIndexFilesService } from "../../services/manageFiles.service";
type Anchor = "right";

const HistoryDrawerComponent = (props: HistoryDrawerInterface& { onDrawerStateChange: (isOpen: boolean) => void }) => {
  const { setQuestion } = props;
  const [snackbarType] = React.useState<string>("");
  const [snackbarMessage] = React.useState<string>("");
  const [openSnackbar, updateOpenSnackbar] = React.useState<boolean>(false);
  const [loadingState] = React.useState<LoaderStateType>("success");
  const [selectedCircle, setSelectedCircle] = React.useState<number>(0);
  const [selectedBox, setSelectedBox] = React.useState<number>(-1);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [isDrawerOpen1, setIsDrawerOpen1] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isHovered1, setIsHovered1] = React.useState(false);
 
  const [sourceValue, setSourceValue] = React.useState('');
  const [pdfName, setPdfName] = React.useState('');
  const [openDialog3, setOpenDialog3] = React.useState(false);
  const [clickedCircle, setClickedCircle] = React.useState<number | null>(null);
  const [firstSetRendered, setFirstSetRendered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const openMenuFlag = Boolean(anchorElMenu);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCircleClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    event!.stopPropagation();
    setSelectedCircle(index);
    setSelectedBox(-1);
    setClickedCircle(0);
  };
  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

  };
  React.useEffect(() => {
    setFirstSetRendered(true);
}, []);

  const handleClose = () => {
    setAnchorElMenu(null);
  };
  const [state, setState] = React.useState({
    right: false,
  });

  const historyReducer = useSelector((state: RootState) => state.history);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setAnchorElMenu(null);
        setState({ ...state, [anchor]: open });
        setSelectedCircle(0);
        setSelectedBox(-1);
        // setIsDrawerOpen1(open);
        props.onDrawerStateChange(open);
        // event.stopPropagation();
      };
  const circleContent = [
    { summary: "Customer Account", detail: "Offers & Incentives" },
    { summary: "Related", detail: "Index" },
    { summary: "Live Transcript", detail: "Call Summary" }
  ];
  const selectedContent = circleContent[selectedCircle];

  const handleClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    event!.stopPropagation();
    setSelectedBox(index);
    const clickedText = (event.target as HTMLElement).textContent;
    if (clickedText === "Related") {
      // Set clickedCircle to 1 if "Related Documents" is clicked
      setClickedCircle(1);
    } else {
      // Reset clickedCircle if other boxes are clicked
      setClickedCircle(null);
    }
  };
  const handlepdfclick = (source: string, pageNumber: number) => {
    // Open the PDF in a new tab
    let url = source.replace("http:", "https:");
    window.open(`${url}#page=${pageNumber}`, "_blank");

    // Additional code you may want to keep
    setOpenDialog3(true);
    let srcHeader = decodeURIComponent(source);
    let pageHeader = srcHeader.slice(srcHeader.lastIndexOf('/') + 1);
    setPdfName(pageHeader);
    setSourceValue(`${url}#page=${pageNumber}`);
    //dialogPdfContent= `${env.REACT_APP_API_URL}${source}#page=${pageNumber}`;
  }

 
  const storedValue = sessionStorage.getItem('sources');


  const links = (): JSX.Element[] => {
    const urls: JSX.Element[] = [];
    let previousId: string | null = null;
   
   
    if (storedValue !== null) {
        const sourcesObject = JSON.parse(storedValue);
   
        if (sourcesObject) {
            Object.values(sourcesObject).forEach((sourceObject: any, index: number, array: any[]) => {
                const id = sourceObject.id;
   
                if (previousId !== null && previousId !== id) {
                   
                    urls.push(
                        <div key={`divider-${previousId}`} style={{ borderTop: "1px solid black", margin: "15px",marginLeft:'10%',marginRight:'16%' }}></div>
                    );
                }
   
                const url = sourceObject.source;
                const type = sourceObject.source_type;
                const pg = sourceObject.page;
   
                urls.push(
                    <ListItem
                        key={url}
                        sx={{ fontSize: "12px", paddingTop: "0px", paddingBottom: "0px" }}
                        className="text-wrap"
                    >
                        <span className="col-12 text-wrap list-item" style={{ "fontSize": "12px" }} >
                            <span style={{ display: "inline-flex" }} >
                                <div
                                    style={{ fontSize: "inherit", color: "#00A9E0", textDecoration: "none" }}
                                >
                                    <a
                                        href={sourceValue}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => handleOpenUrlInNewTab(e, url, pg, type)}
                                        style={{ fontSize: "inherit", color: "#00A9E0", textDecoration: "none" }}>
                                        <span style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: url.slice(url.lastIndexOf('/') + 1).charAt(0).toUpperCase() + url.slice(url.lastIndexOf('/') + 1).slice(1).replace(/%\d+/g, ' ') }} />
                                    </a>
                                    <span style={{ color: 'black' }}> -</span>
                            <span className="ml-1" style={{ fontSize: "12px", color: 'black' }} onClick={(e) => e.preventDefault()}>
                                {type === 'sce.com' || type === 'web-pages' || type === 'news-posts' || type === 'sharepoint-lists' ? null : " Pg."}
                                {type === 'sce.com' ? ' sce.com' : type === 'web-pages' ? ' web pages' : type === 'news-posts' ? ' news posts'  : type ==='sharepoint-lists' ? 'sharepoint-lists' : pg}
                            </span>
                                </div>
                            </span>
                            
                        </span>
                    </ListItem>
                );
   
                previousId = id;
   
                if (index === array.length - 1) {
                    urls.push(
                        <div key={`divider-${id}`} style={{ borderTop: "1px solid black", margin: "15px",marginLeft:'10%',marginRight:'16%'  }}></div>
                    );
                }
            });
        }
    }
   
    return urls;
  };
  const [userInfo, setUserInfo] = React.useState({
    userId: "test@sce.com"
  });
  const [activeIndex, setActiveIndex] = React.useState();
  const [resdata, setResdata] = React.useState(
    {
      'status': '',
      'message': '',
      'file_urls': [],
      'file_names': []
    }
  );
  const indexval = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const handleindexClick = (e: { target: any }) => {
    getIndexFilesService(userInfo.userId, e.target.value).then((res: any) => {
      setResdata(res.data);
    })
      .catch((_err: any) => { });
    setActiveIndex(e.target.value);
  }
const handleOpenUrlInNewTab = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string, pg:string,type:string) => {
  if (type === 'news-posts') {
    
    window.open(url, "_blank");
  } else {
    
    let srcHeader = decodeURIComponent(url);
    
    let pageHeader = srcHeader.slice(srcHeader.lastIndexOf('/') + 1);
    
    setPdfName(pageHeader);
   
    let urlss = url.replace("http:", "https:");
      setSourceValue(`${urlss}#page=${pg}`);
  }
};

  const list = (anchor: Anchor) => (




    <Box
      sx={{ width: 450 }}
      role="presentation"
      onClick={handleContainerClick}
  
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >

        <IconButton
          onClick={toggleDrawer(anchor, false)}
          size="medium"
          color="primary"
          sx={{ top: '62px', right: '32px', marginRight: '-16%' }}

        >
          <ArrowRightIcon sx={{ color: '#00664FCC', fontSize: '48px' }} />
        </IconButton>

        <div
          style={{
            background: selectedCircle === 0 ? '#00664FCC' : '#F0F0F0',
            color: selectedCircle === 0 ? '#FFFFFF' : '#898989',
            width: '110px',
            height: '45px',
            top: '61px',
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '1.5',
            cursor: 'pointer',
            borderBottom: 'none',
            position: 'relative',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            paddingTop: '8px',
            borderTop: '1px solid grey', borderRight: '1px solid grey', borderLeft: '1px solid grey'
          }}
          onClick={(event) => handleCircleClick(0, event)}
        >
          Account Info
        </div>
        <div
          style={{
            background: selectedCircle === 1 ? '#00664FCC' : '#F0F0F0',
            color: selectedCircle === 1 ? '#FFFFFF' : '#898989',
            width: '110px',
            height: '45px',
            top: '61px',
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '1.5',
            cursor: 'pointer',
            position: 'relative',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            paddingTop: '8px',
            borderTop: '1px solid grey', borderRight: '1px solid grey', borderLeft: '1px solid grey'
          }}
          onClick={(event) => handleCircleClick(1, event)}
        >
          Helpful Docs
        </div>
        <div
          style={{
            background: selectedCircle === 2 ? '#00664FCC' : '#F0F0F0',
            color: selectedCircle === 2 ? '#FFFFFF' : '#898989',
            width: '110px',
            height: '45px',
            top: '61px',
            marginRight: '10%',
            fontSize: '14px',
            textAlign: 'center',
            cursor: 'pointer',
            position: 'relative',
            paddingTop: '9px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '1px solid grey', borderRight: '1px solid grey', borderLeft: '1px solid grey'
          }}
          onClick={(event) => handleCircleClick(2, event)}
        >
          Transcription
        </div>
      </Box>

     
      <div style={{ position: 'relative', top: '35px', backgroundColor: '#00664FCC', height: '3px' }}>
        <div
          style={{
            textDecoration: selectedBox === 0 ? 'underline' : 'none',
            cursor: 'pointer',
            backgroundColor: '#FFFFFF',
            width: '19%',
            top: '16px',
            position: 'relative',
            fontSize: '14px',
            textAlign: 'center',
            color: selectedBox === 0 ? 'black' : '#898989',
            left: '36px',
            height: '40px',

            border: `1px solid ${isHovered ? 'grey' : 'transparent'}`,
            fontWeight: selectedBox === 0 ? 'bold' : 'unset',
            transition: 'border-color 0.3s'
          }}
          onClick={(event) => handleClick(0, event)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {selectedContent.summary}
        </div>

        <div style={{
          textDecoration: selectedBox === 1 ? 'underline' : 'none', cursor: 'pointer', backgroundColor: '#FFFFFF', width: '19%', bottom: '24px', position: 'relative', color: selectedBox === 1 ? 'black' : '#898989', left: '148px', height: '40px', fontSize: '14px',
          textAlign: 'center',
          border: `1px solid ${isHovered1 ? 'grey' : 'transparent'}`,
          fontWeight: selectedBox === 1 ? 'bold' : 'unset',
          transition: 'border-color 0.3s'
        }}
          onClick={(event) => handleClick(1, event)}
          onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}
        >
          {selectedContent.detail}
        </div></div>
        <div style={{ position: 'relative', top: '95px', backgroundColor: '#898989', height: '2px',marginRight:'17%',marginLeft:'7%'}}></div>
        {clickedCircle === 1 && (
    <div>
        <Typography sx={{ position: 'relative', top: '110px',paddingRight:'40px' }}>
       
            {links()}
        </Typography>
 
      
    </div>
)}


      {/* <Typography variant="h6" sx={{ pt: 1, pl: 3, pr: 3, pb: 0 }}>
        History
      </Typography>
      <Divider
        sx={{ mt: 2, p: 0, bgcolor: "white" }}
        variant="middle"
        className="divider"
      />
      <IconButton className="close-icon">
        <CloseIcon />
      </IconButton> */}
      {/* <List>
        {historyReducer.questions.map((question: QuestionInterface, index) => (
          <ListItem
            key={`history-${question.id}`}
            sx={{ p: 0, pl: 1 }}
            onClick={() => itemClicked(question)}
          >
            <ListItemButton>
              <ListItemText
                secondaryTypographyProps={{
                  sx: {
                    color: "white",
                    fontWeight: 100,
                  },
                }}
                primary={question.question}
                secondary={new Date(question.dateCreated).toLocaleString("en-US")}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const itemClicked = (question: QuestionInterface) => {
    setQuestion(question);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log(event);
    if (reason === "clickway") {
      return;
    }

    updateOpenSnackbar(false);
  };

  return (
    <>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
         
          <Fab
            onClick={openMenu}
            size="medium"
            color="primary"
            className="more-actions-icon"
          >
            <ArrowLeftIcon sx={{ color: '#00664F', fontSize: '48px' }} onClick={toggleDrawer(anchor, true)} />
          </Fab>
       

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            variant='persistent'
 
            PaperProps={{
              className: "drawer-paper",
              sx: {
                color: "#fff",
                backgroundColor: "#FFFFF",
                width: '400px',
                overflowX: 'hidden',
                boxShadow: '1px 0px 6px #00000029',
                borderLeft: 'none',
                border:'1px solid #00664F',
                
              },
            }}

            ModalProps={{

              slots: {
                backdrop: "div"
              },
              slotProps: {
                root: {
                  style: {
                    position: "absolute",
                    top: "unset",
                    bottom: "unset",
                    left: "unset",
                    right: "unset",
                  },
                },
              },
            }}
          >


            {list(anchor)}
            {selectedContent.detail == "Index" && selectedBox === 1 &&
              <div className="row" style={{ color: "black", marginTop: "110px", marginLeft: "10px" }}>
                <div className="col-12">
                  {indexval.map((val) => (
                    <Button
                      className={activeIndex === val ? 'indexbtn active' : 'indexbtn'}
                      onClick={(e) => handleindexClick(e)}
                      value={val}
                      style={{
                        margin: "2px",
                        background: "rgb(240, 240, 240)",
                        color: "black",
                        border: "1px solid",
                        width: "35px"
                      }}>
                      {val}
                    </Button>
                  ))}
                </div>
                <div className="col-12" style={{ marginTop: "10px" }}>
                  {resdata.message == 'File not found!' ? 'File not found!' :
                    <ul>
                      {resdata.file_names.map((val, index) =>
                      (
                        <React.Fragment>
                          <li><a href={resdata.file_urls[index]}>{val}</a></li>
                        </React.Fragment>
                      )
                      )}
                    </ul>
                  }
                </div>
              </div>
            }

          </Drawer>

          {/* <>
            <Menu
              id="basic-menu"
              anchorEl={anchorElMenu}
              open={openMenuFlag}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={toggleDrawer(anchor, true)}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText>Show History</ListItemText>
              </MenuItem>
            </Menu>
          </> */}
          <CustomSnackbar
            isDrawerOpen2={isDrawerOpen1}
            openSnackbar={openSnackbar}
            type={snackbarType}
            message={snackbarMessage}
            handleSnackBarClose={handleSnackbarClose}
          />
          {loadingState === "loading" && <LoaderComponent />}
        </React.Fragment>
      ))}
    </>
  );
};

export default HistoryDrawerComponent;
