import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigationSliceInterface } from "../../interfaces/navigation.interface";

const initialState: NavigationSliceInterface = {
  appName: "Advise AI",
  currentRole:
    window.location.href && window.location.href.toString().includes("admin")
      ? "admin"
      : "normal",
  dashBoard: true,
};

export const NavigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    updateAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },
    updateCurrentRole: (state) => {
      state.currentRole =
        window.location.href &&
        window.location.href.toString().includes("admin")
          ? "admin"
          : "normal";
    },
    updatedashBoard: (state, action: PayloadAction<boolean>) => {
      state.dashBoard = action.payload;
    },
  },
});

export const { updateAppName, updateCurrentRole, updatedashBoard } = NavigationSlice.actions;
export default NavigationSlice.reducer;
