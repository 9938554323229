import axios from "axios";

const env = process.env;

export const getDashboardsessionService = ( startdate:string, enddate:string, mode:string ): Promise<any> => {
    const start_date = startdate;
    const end_date = enddate;

    return axios.post(`${env.REACT_APP_API_URL}/dashboard/session`, {
        start_date,end_date,mode
    });
}

export const getDashboardfeedbackService = ( startdate:string, enddate:string, mode:string ): Promise<any> => {
    const start_date = startdate;
    const end_date = enddate;

    return axios.post(`${env.REACT_APP_API_URL}/dashboard/feedback`, {
        start_date,end_date,mode
    });
}

export const getDashboarderrorService = ( startdate:string, enddate:string, mode:string ): Promise<any> => {
    const start_date = startdate;
    const end_date = enddate;

    return axios.post(`${env.REACT_APP_API_URL}/dashboard/error`, {
        start_date,end_date,mode
    });
}

export const getDashboardtopicService = ( startdate:string, enddate:string, mode:string ): Promise<any> => {
    const start_date = startdate;
    const end_date = enddate;

    return axios.post(`${env.REACT_APP_API_URL}/dashboard/topic`, {
        start_date,end_date,mode
    });
}
export const getReportService = (user_id: string, startdate:string, enddate:string, mode:string ): Promise<any> => {
    const start_date = startdate;
    const end_date = enddate;
 
    return axios.post(`${env.REACT_APP_API_URL}/dashboard/download`, {
        user_id,start_date,end_date,mode
    });
}
