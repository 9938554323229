// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Signika.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Signika";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-display: swap;
}
@media screen and (min-width: 3840px) {
  body {
    transform: scale(1, 5);
    transform-origin: top left;
  }
}
body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,4CAAA;EACA,kBAAA;AACJ;AAEA;EACI;IACI,sBAAA;IACA,0BAAA;EAAN;AACF;AAGA;EACI,SAAA;AADJ;;AAKA;EACI,UAAA;EACA,WAAA;AAFJ;;AAMA;EACI,yBAAA;AAHJ;;AAMA;EACI,sBAAA;AAHJ","sourcesContent":["@font-face {\n    font-family: 'Signika';\n    src: url('./fonts/Signika.ttf');\n    font-display: swap;\n}\n\n@media screen and (min-width: 3840px) {\n    body{\n        transform: scale(1,5);\n        transform-origin: top left;\n    }\n}\n\nbody {\n    margin: 0;\n}\n\n\n::-webkit-scrollbar {\n    width: 7px;\n    height: 7px;\n}\n\n\n::-webkit-scrollbar-track {\n    background-color: #f1f1f1;\n}\n\n::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {\n    background-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
