import * as React from 'react';
import "./dashboard.component.scss";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ErrorIcon from '@mui/icons-material/Error';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import TopicIcon from '@mui/icons-material/Topic';
import Sessions from "./sessions/sessions";
import Feedback from "./feedback/feedback";
import Errors from "./errors/errors";
import Topics from "./topics/topics";
import { useDispatch, useSelector } from "react-redux";
import { updatedashBoard } from "../../stores/slices/navigation.slice";
const drawerWidth = 180;
const DashboardComponent = () => {
    const dispatch = useDispatch();
    const [navselected, setNavselected] = React.useState('Sessions');
    const handleSideBar = (event: React.BaseSyntheticEvent, val: string) => {
        setNavselected(val);
    }
    React.useEffect(() => {
        dispatch(updatedashBoard(false));
      }, []);

    return (
        <>
            <Box sx={{ display: 'flex', marginTop: "64px" }}>
                <CssBaseline />
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <Toolbar />
                    <Divider />
                    <List>
                        {/* {['Sessions', 'Feedback', 'Errors', 'Topics'].map((text, index) => ( */}
                        <ListItem key='Sessions' className={navselected=="Sessions"?'activenav':'inactivenav'} disablePadding onClick={(event) => { handleSideBar(event, 'Sessions') }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AdfScannerIcon />
                                </ListItemIcon>
                                <ListItemText primary='Sessions' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key='Feedback' className={navselected=="Feedback"?'activenav':'inactivenav'} disablePadding onClick={(event) => { handleSideBar(event, 'Feedback') }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FeedbackIcon />
                                </ListItemIcon>
                                <ListItemText primary='Feedback' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key='Errors' className={navselected=="Errors"?'activenav':'inactivenav'} disablePadding onClick={(event) => { handleSideBar(event, 'Errors') }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ErrorIcon />
                                </ListItemIcon>
                                <ListItemText primary='Errors' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key='Topics' className={navselected=="Topics"?'activenav':'inactivenav'} disablePadding onClick={(event) => { handleSideBar(event, 'Topics') }}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <TopicIcon />
                                </ListItemIcon>
                                <ListItemText primary='Topics' />
                            </ListItemButton>
                        </ListItem>
                        {/* ))} */}
                    </List>
                    <Divider />

                </Drawer>
                {navselected == 'Sessions' && <Sessions />}
                {navselected == 'Feedback' && <Feedback />}
                {navselected == 'Errors' && <Errors />}
                {navselected == 'Topics' && <Topics />}
            </Box>

        </>
    );
}

export default DashboardComponent;
