import "../home.component.scss";

import * as React from "react";

import { QuestionInterface, QuestionRatingType } from "../../../interfaces/question.interface";

import QuestionItemComponent from "../questionItem/questionItem.component";
import { RootState } from "../../../stores/store";
import WelcomeComponent from "../welcomeScreen/welcome.component";
import WelcomeScreenComponent from "../welcomeScreen/welcomeScreen.component";
import { useSelector } from "react-redux";

const QuestionSectionComponent = (props: {
  updateRating: (question: QuestionInterface, rating: QuestionRatingType) => void;
sendQuestion: (event: React.BaseSyntheticEvent) => void;
isDrawerOpen2: boolean;
}) => {
  const { updateRating,sendQuestion ,isDrawerOpen2} = props;
  const questionReducer = useSelector((state: RootState) => state.question);
    var objDiv = document.getElementById("resultbottom");
//     if (objDiv) {
//    objDiv.scrollTop = objDiv.scrollHeight;
// }
  return (
    <div className={`result-container ${isDrawerOpen2 ? 'container' : ''}`} id="resultbottom" >
      <div className="col-8 offset-2 mt-3 ">
        <div className="row imgnew">
        <WelcomeComponent />
          {questionReducer.questions.map((question: QuestionInterface) => (
            <QuestionItemComponent
              question={question}
              key={question.id}
              updateRating={updateRating} 
              sendQuestion={sendQuestion} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default QuestionSectionComponent;
