// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
  display: flex;
}

.sessionperhour {
  border: 1px solid gray;
}

.questionperhour {
  border: 1px solid gray;
}

.usersperhour {
  border: 1px solid gray;
  margin-top: 20px;
}

.chatname {
  padding: 10px;
}

.activenav {
  background: #e3e3e3;
}

.topictable {
  height: 440px;
}

.errormsg {
  color: #fe2c2c;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AACA;EACI,cAAA;AAEJ","sourcesContent":[".flex-container {\r\n    display: flex;\r\n}\r\n\r\n.sessionperhour {\r\n    border: 1px solid gray;\r\n}\r\n\r\n.questionperhour {\r\n    border: 1px solid gray;\r\n}\r\n\r\n.usersperhour {\r\n    border: 1px solid gray;\r\n    margin-top: 20px;\r\n}\r\n\r\n.chatname {\r\n    padding: 10px;\r\n}\r\n\r\n.activenav {\r\n    background: #e3e3e3;\r\n}\r\n\r\n.topictable {\r\n    height: 440px;\r\n}\r\n.errormsg {\r\n    color:#fe2c2c\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
