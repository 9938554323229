import { configureStore } from "@reduxjs/toolkit";
import NavigationReducer from "./slices/navigation.slice";
import QuestionReducer from "./slices/questions.slice";
import HistoryReducer from "./slices/history.slice";
import FileReducer from "./slices/file.slice";

export const store = configureStore({
  reducer: {
    navigation: NavigationReducer,
    question: QuestionReducer,
    files: FileReducer,
    history: HistoryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
