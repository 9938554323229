import { Button, Paper } from "@mui/material";
import {
  useState,
  useRef,
  BaseSyntheticEvent,
  SyntheticEvent,
  useEffect,
} from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoaderComponent from "../loader/loader.component";
import CustomHr from "../customHR/customhr";
import CustomSnackbar from "../customSnackBar/customSnackBar.component";
import { LoaderStateType } from "../../types/common.types";
import {
  uploadFileService,
  fetchFilesService,
} from "../../services/manageFiles.service";
import FileListComponent from "./filesList/filesList.component";
import "./manageFiles.component.scss";
import { useDispatch } from "react-redux";
import {
  addFile,
  bulkUpdateFileName,
  deleteFilesFromStore,
  setSelected,
} from "../../stores/slices/file.slice";
import { FileInterface } from "../../interfaces/files.interface";
import AlertCompoent from "../alert/alert.component";

const ManageFilesComponent = () => {
  const dispatch = useDispatch();
  const [pdfFile, setPdfFile] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const pdfFileRef = useRef<HTMLInputElement>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [loadingResults, setLoadingResults] =
    useState<LoaderStateType>("success");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  useEffect(() => {
    fetchFilesService()
      .then((res: any) => {
        if (res && res.data) {
          dispatch(bulkUpdateFileName(res.data));
          setPageLoading(false);
        }
      })
      .catch(() => {
        setSnackbarMessage("Unable to load Files.");
        setSnackbarType("error");
        setOpenSnackBar(true);
        setPageLoading(false);
      });
  }, [dispatch]);

  const handleFileChange = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    event.preventDefault();
    const file = event.target.files[0];
    const fileName: string = file.name;
    const fileId = event.target.id;

    if (fileId === "pdf-file") {
      setPdfFile(file);
      setPdfFileName(fileName);
    }
  };

  const handleReset = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setPdfFile("");
    setPdfFileName("");

    if (pdfFileRef.current) {
      pdfFileRef.current.value = "";
    }
  };

  const handleSnackbarClose = (
    event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const uploadFile = () => {
    setLoadingResults("loading");
    const formData = new FormData();
    formData.append("pdf_file", pdfFile);
    uploadFileService(formData)
      .then((res: any) => {
        const newFile: FileInterface = {
          fileName: pdfFileName,
          dateCreated: new Date().getTime(),
        };

        dispatch(addFile([newFile]));
        console.log(res);
        setSnackbarMessage(res.data.message);
        setSnackbarType(res.data.status);
        setOpenSnackBar(true);
        setLoadingResults("success");
        handleReset();
      })
      .catch((err) => {
        setSnackbarMessage("Unable to upload file.");
        setSnackbarType("error");
        setOpenSnackBar(true);
        setLoadingResults("success");
      });
  };

  const deleteFiles = (filesSelected: string[]) => {
    setLoadingResults("loading");
    console.log(filesSelected);
    dispatch(deleteFilesFromStore(filesSelected));
    dispatch(setSelected([]));
    setSnackbarMessage("Files Deleted Successfully.");
    setSnackbarType("success");
    setOpenSnackBar(true);
    setLoadingResults("success");
  };

  return (
    <>
      <div className="container-fluid">
        {!pageLoading && (
          <div className="row">
            <div className="col-12">
              <h5 className="text-center pt-3 pb-1">
                File Manager
                <CustomHr />
              </h5>
            </div>
            {loadingResults !== "error" && (
              <>
                <Paper
                  className="col-10 offset-1 upload-parent pt-3 pb-3"
                  elevation={0}
                >
                  <div className="row">
                    <div className="col-12 text-center">
                      <label htmlFor="pdf-file">
                        <input
                          type="file"
                          name="pdf-file"
                          id="pdf-file"
                          ref={pdfFileRef}
                          onChange={handleFileChange}
                          accept="application/pdf"
                        />
                        <Button
                          variant="outlined"
                          component="span"
                          size="medium"
                          color="inherit"
                          startIcon={<InsertDriveFileIcon />}
                          sx={{ fontWeight: "bold", fontFamily: "Signika" }}
                        >
                          Choose a PDF File to UPLOAD
                        </Button>
                      </label>
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <h6 className="text-center">
                        {pdfFile ? pdfFileName : "No File Selected"}
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-3 text-end">
                      <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<RefreshIcon />}
                        sx={{ fontWeight: "bold", fontFamily: "Signika" }}
                        disabled={!pdfFile}
                        onClick={handleReset}
                      >
                        RESET
                      </Button>
                    </div>
                    <div className="col-6 mt-3 text-start">
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<UploadFileIcon />}
                        sx={{ fontWeight: "bold", fontFamily: "Signika" }}
                        disabled={!pdfFile}
                        onClick={uploadFile}
                      >
                        UPLOAD
                      </Button>
                    </div>
                  </div>
                </Paper>
                <div className="col-10 offset-1 mt-3 mb-3">
                  <FileListComponent deleteSelectedFiles={deleteFiles} />
                </div>
              </>
            )}
          </div>
        )}
        {(loadingResults === "loading" || pageLoading) && <LoaderComponent />}
        <CustomSnackbar
        isDrawerOpen2={true}
          openSnackbar={openSnackBar}
          handleSnackBarClose={handleSnackbarClose}
          message={snackbarMessage}
          type={snackbarType}
        />
      </div>
      <AlertCompoent />
    </>
  );
};

export default ManageFilesComponent;
