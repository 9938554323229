// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-image {
  width: 100px;
  margin-right: 5px;
  cursor: pointer;
}

.divider {
  color: white !important;
  background: white !important;
  border-width: 1px !important;
  border-color: white !important;
  opacity: 1 !important;
  margin-left: 5px !important;
  margin-right: 10px !important;
}

.button-text {
  margin-right: 3px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/navigation.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;EACA,eAAA;AACJ;;AAGA;EAEI,uBAAA;EACA,4BAAA;EACA,4BAAA;EACA,8BAAA;EACA,qBAAA;EACA,2BAAA;EACA,6BAAA;AADJ;;AAKA;EACI,4BAAA;AAFJ","sourcesContent":[".logo-image {\r\n    width: 100px;\r\n    margin-right: 5px;\r\n    cursor: pointer;\r\n    \r\n}\r\n\r\n.divider {\r\n    $margin: 5px;\r\n    color: white !important;\r\n    background: white !important;\r\n    border-width: 1px !important;\r\n    border-color: white !important;\r\n    opacity: 1 !important;\r\n    margin-left: $margin !important;\r\n    margin-right: 10px !important;\r\n}\r\n\r\n\r\n.button-text {\r\n    margin-right: 3px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
