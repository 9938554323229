import { useEffect, useState } from "react";

import FullScreenText from "../../components/FullScreenText";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

/* Wrapper to set active user */
const ActiveUserWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { instance: msalInstance, inProgress } = useMsal();

  const [accountLoaded, setAccountLoaded] = useState(false);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;
    const accounts = msalInstance.getAllAccounts();
    const account = accounts.find(
      (account) =>
        account.username.toLowerCase().endsWith("@sce.com") ||
        account.username.toLowerCase().endsWith("@edisonintl.com")
    );

if (account) {
      msalInstance.setActiveAccount(account);
      setAccountLoaded(true);
      //console.log("account-->",account.name);
      if(account.name!=undefined){
      sessionStorage.setItem("username", account.name);
      sessionStorage.setItem("email", account.username);}
     
    }
  }, [inProgress]);

  return accountLoaded ? (
    <>{children}</>
  ) : (
    <FullScreenText> </FullScreenText>
  );
};

export default ActiveUserWrapper;
