import * as React from "react";
import {  List, Box, Button,CssBaseline, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, CSSObject, Theme, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoaderStateType } from "../../types/common.types";
import { RootState } from "../../stores/store";

import { QuestionInterface, QuestionRatingType } from "../../interfaces/question.interface";
import {
  addNewQuestion,
  clearState,
  updateRating,
} from "../../stores/slices/questions.slice";
import {
  addNewQuestion as addNewQuestionToHistory,
  markEverythingAsHistory,
} from "../../stores/slices/history.slice";
import { getAnswerService, rateQuestionService } from "../../services/questions.service";

import "./home.component.scss";
import { getDbHistory, newConversationInitiaited } from "../../services/history.service";

import axios from "axios";

import MuiDrawer from '@mui/material/Drawer';

import { ZoomIn } from "@mui/icons-material";
import { TrendingUp } from "@mui/icons-material";
import { Apartment } from "@mui/icons-material";
import { Calculate } from "@mui/icons-material";
import { TextSnippet } from "@mui/icons-material";
import { Contacts } from "@mui/icons-material";
import { Newspaper } from "@mui/icons-material";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import { History } from "@mui/icons-material";

import { Link } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Close } from "@mui/icons-material";

import { Add } from "@mui/icons-material";
const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
});


const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// const detectMobile = () => {
//   navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)|| navigator.userAgent.match(/Windows Phone/i)? 
//     true: false
//   };


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const MobileHomeComponent = () => {
  const questionReducer = useSelector((state: RootState) => state.question);
  const dispatch = useDispatch();
 
  const [loadingState, updateLoadingState] =
    React.useState<LoaderStateType>("loading");
  const [questionText, updateQuestionText] = React.useState<string>("");
  const [snackbarType, updateSnackbarType] = React.useState<string>("");
  const [snackbarMessage, updateSnackbarMessage] = React.useState<string>("");
  const [openSnackbar, updateOpenSnackbar] = React.useState<boolean>(false);
  const [requestSent, updateRequestSent] = React.useState<boolean>(false);
  const historyReducer = useSelector((state: RootState) => state.history);
  const [dbHistoryData, setDbHistoryData] = React.useState<QuestionInterface[]>([]);
  var [showHistory, setShowHistory] = React.useState<QuestionInterface[]>([]);
  const [searchQuery,] = React.useState("");
  const [sortOption, ] = React.useState("newest");
  const [moreButtonRender, ] = React.useState(true);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   open ? setOpen(false) : setOpen(true)
    
  // };
const isMobile= window.matchMedia('(max-width: 767px)');
  const env = process.env;

  React.useEffect(() => {
    updateLoadingState("success");
    /*getDbHistory().then((data) => {
      setDbHistoryData(data);
      if (moreButtonRender) {
        setShowHistory(data.slice(0, 10))
      }
      else {
        setShowHistory(data)
      }
      ;
    });*/
  }, [moreButtonRender]);

  // const handleSnackbarClose = (
  //   event?: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   console.log(event);
  //   if (reason === "clickway") {
  //     return;
  //   }

  //   updateOpenSnackbar(false);
  // };

  // const showSnackbar = (message: string, type: string) => {
  //   updateSnackbarMessage(message);
  //   updateSnackbarType(type);
  //   updateOpenSnackbar(true);
  // };

  // const onQuestionValueChange = (event: React.BaseSyntheticEvent) => {
  //   updateQuestionText(event.target.value);
  // };

  const bringNewQuestionToFocus = (questionId: number | string) => {
    setTimeout(() => {
      const element: HTMLElement | null = document.getElementById(
        `question-${questionId}`
      );

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 1000 / 60);
  };

  const setQuestionFromHistory = (question: QuestionInterface) => {
    if (questionReducer.questions.findIndex((formQuestion: QuestionInterface) => formQuestion.id === question.id) === -1) {
      dispatch(addNewQuestion([question]));
    }
    setTimeout(() => {
      // updateQuestionText(question.question);
      bringNewQuestionToFocus(question.id);
    }, 1000 / 60);
  };

  // const sendQuestion = (event: React.SyntheticEvent) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   updateLoadingState("loading");
  //   const startTime = performance.now();
  //   getAnswerService(questionText)
  //     .then((res: any) => {
  //       updateRequestSent(false);
  //       if (res.data) {
  //         const endTime = performance.now();
  //         const timeStamp: number = new Date().getTime();
  //         const idTimestamp: number = parseInt(new Date().toISOString().replace(/[-:.TZ]/g, "").slice(0, 14));
  //         const tempQuestion: QuestionInterface = {
  //           ...res.data,
  //           question: questionText,
  //           id: idTimestamp,
  //           dateCreated: timeStamp,
  //           timeTakenToRespond: `${(endTime - startTime) / 1000}`,
  //           rating: 0,
  //         };
  //         dispatch(addNewQuestion([JSON.parse(JSON.stringify(tempQuestion))]));
  //         // tempQuestion.history = true;
  //         dispatch(addNewQuestionToHistory([tempQuestion]));
  //         dbHistoryData.unshift(tempQuestion);
  //         showHistory.unshift(tempQuestion);
  //         if (moreButtonRender) {
  //           setShowHistory(showHistory.slice(0, 10))
  //         }
  //         updateLoadingState("success");
  //         bringNewQuestionToFocus(tempQuestion.id);
  //         updateQuestionText("");
  //         showSnackbar("Your results are ready.", "success");

  //       }
  //     })
  //     .catch((err: any) => {
  //       updateRequestSent(false);
  //       console.log(err);
  //       updateLoadingState("success");
  //       showSnackbar(
  //         "Unable to fetch results. Please try after sometime.",
  //         "error"
  //       );
  //     });
  // };

  // const handleKeyBoardEvent = (event: React.KeyboardEvent) => {
  //   if (event.key === "Enter") {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     if (!requestSent) {
  //       sendQuestion(event);
  //       updateRequestSent(true);
  //     }
  //     return;
  //   }
  // };

  // const newConversation = (event: React.SyntheticEvent) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   updateLoadingState("loading");
  //   newConversationInitiaited()
  //     .then((res: any) => {
  //       dispatch(markEverythingAsHistory());
  //       dispatch(clearState());
  //       updateSnackbarMessage("New Conversation Initiated");
  //       updateSnackbarType("success");
  //       updateLoadingState("success");
  //       updateOpenSnackbar(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       updateSnackbarMessage("Unable to initiate new conversation");
  //       updateSnackbarType("error");
  //       updateLoadingState("success");
  //       updateOpenSnackbar(true);
  //     });
  // };

  // const updateQuestionRating = async (question: QuestionInterface, rating: QuestionRatingType) => {
  //   updateLoadingState("loading");
  //   try {
  //     const resp = await rateQuestionService(question, rating);
  //     console.log(resp);
  //     dispatch(
  //       updateRating(
  //         { question, rating }
  //       )
  //     );
  //     updateLoadingState("success");
  //     showSnackbar(rating === 0 ? "Question rating removed." : "Question rated successfully.", "success");
  //   } catch (error) {
  //     updateLoadingState("success");
  //     showSnackbar("Unable to rate question", "error");
  //   }
  // }
  const handleRelatedTopicClick = async (question: QuestionInterface) => {
    const response = await axios.post(
      `${env.REACT_APP_API_URL}/get_topic`,
      { "topic": question.default_topic },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const dataArray = response.data.Result_list;

    var skipIdArray = []
    for (let i = 0; i < dataArray.length; i++) {
      const data = dataArray[i];

      if (document.getElementById(`question-${data.RowKey}`)) {
        skipIdArray.push(data.RowKey)
        continue;
      }

      const tempQuestion: QuestionInterface = {
        ...data,
        question: data.Question,
        response: data.answer,
        id: data.RowKey,
        dateCreated: data.RowKey,
        rating: 0,
        timeTakenToRespond: data.responseTime,
        questionSource: "Related Topic"
      };

      dispatch(addNewQuestion([JSON.parse(JSON.stringify(tempQuestion))]));
      if (i === 0) {
        bringNewQuestionToFocus(tempQuestion.id);
      }
    }
    if (skipIdArray.length === dataArray.length && skipIdArray.length !== 0) {
      bringNewQuestionToFocus(Math.min(...skipIdArray));
    }
  };

  showHistory = showHistory.sort((a, b) => {
    if (sortOption === "newest") {
      return b.id - a.id;
    } else if (sortOption === "oldest") {
      return a.id - b.id;
    } else {
      return 0;
    }
  });


  showHistory = showHistory.filter((data) =>
    data.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const relatedTopics: QuestionInterface[] = [];
  for (const item of historyReducer.questions) {
    const topicExists = relatedTopics.some(topicItem => topicItem.default_topic === item.default_topic);
    if (!topicExists) {
      relatedTopics.push(item);
    }
  }
// const renderMenuDrawer = () => {
//     return(
       
//     <Button
//     sx={{
//       minHeight: "30px",
//       justifyContent: open ? 'initial' : 'center',
//       px: 2.5,
//       color: "#19191D",
//       font: "normal normal normal 24px/32px Segoe UI"
//       // border:"2px solid rgb(88, 137, 53)",
//       // borderRadius:"5px",
//       // bgcolor:"rgba(88, 137, 53, 0.5)"
//     }}
//     className={open ? 'mx-1 py-0 my-0' : ''}
//     onClick={() =>renderMiniDrawer()}
//   >
    
      

// {/* {isMobileDevice?<ZoomIn />:null} */}
//       <Menu />
//     {/* <ListItemText primary={"Advise AI"} sx={{ opacity: open ? 1 : 0, }} /> */}

//   </Button>
// );}
  const renderMiniDrawer = () => {

    // let isMobileDevice = RegExp.test(details); 
    return (
      
      <Box sx={{
        display: 'flex',
      }}>
        <CssBaseline />
        {/* <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar> */}
        <Drawer variant="permanent" open={open}>
          <List sx={{ marginTop: "10px", height: "90%", maxHeight: "90%", }} onClick={handleDrawerOpen}>
            <ListItem key={"Advise AI"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "35vh" }}>
              <ListItemButton
                sx={{
                  minHeight: "30px",
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#19191D",
                  font: "normal normal normal 24px/32px Segoe UI",
                  
                  // border:"2px solid rgb(88, 137, 53)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(88, 137, 53, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 3 : 'auto',
                    mr: 'auto',
                    justifyContent: 'center',
                  }}
                >
                  
  
  {/* {isMobileDevice?<ZoomIn />:null} */}
  <Close sx={{color:"black",marginRight:"10px" }} />
                </ListItemIcon>
                <ListItemText primary={"Advise AI"} sx={{ opacity: open ? 1 : 0, marginLeft:"10px"}} />
                <Add />
              </ListItemButton>
              {open ? (
                //<div style={{
                //  maxWidth: "100%", display: "block", 
                 // height: "calc(100% - 30px)", 
                //  overflowY: "auto"
               // }}>
                  <List sx={{ paddingLeft: 1, display: "grid", flexWrap: "wrap" }}>
                    {
                      relatedTopics.slice(-10).reverse().map((data: QuestionInterface, index: number) => (
                        <Button
                          variant="text"
                          sx={{
                            textAlign: "left",
                            textTransform: "none",
                            width: "100%",
                          }}
                          title={data.question}
                          onClick={() => handleRelatedTopicClick(data)}
                          key={`related-button-${index}`}
                        >
                          <div style={{ width: "100%" }}>
                            {data.default_topic}
                          </div>
                        </Button>
                      ))
                    }
                    {/* {(relatedTopics.length === 0) && (
                      <Typography variant="body2" sx={{ textAlign: "center", mt: 7 }}>
                        No Questions Asked Yet!
                      </Typography>
                    )} */}
                  </List>
                //</div>
              ) : null}
            </ListItem>
            <Divider />
            <ListItem key={"Saved Searches"} disablePadding sx={{ display: 'none',height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
            <Link underline="none">
            {/* <a href="https.sce.com" target="_blank" rel="noopener noreferrer"> */}
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  font: "normal normal normal 18px/24px Segoe UI",
                  pointerEvents:open?"none":"auto",
                 
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                  color: "#FFFFFF",
                  // pointerEvents: "none",
                  // display: "inline-block",
                  background: "#00664FCC 0% 0% no-repeat padding-box",
                  borderRadius: "6px",
                  opacity: "1",
                //   width: open? "290px" : "0px",
                  marginLeft: open? "0px" : "10px",
                  height: "0px",
                //   bottom:"42px"
                //   top: open?"0px" : "8px",
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    borderRadius: "6px",
                    // width: "30px",
                    // left:"10px",
                    // height:"40px",

                  }}
                >
                  <ZoomIn sx={{color:"#FFFFFF"}}/>
                 
                </ListItemIcon>
                <ListItemText primary={"Saved Searches" } sx={{ opacity: open ? 1 : 0,}} />
                <ChevronRight sx={{color:"#FFFFFF"}} />
              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
            <ListItem key={"History"} disablePadding sx={{ display: 'none', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <ListItemButton
                sx={{
                  // minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "15px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <History />
                </ListItemIcon>
                <ListItemText primary={"Past Searches"} sx={{ opacity: open ? 1 : 0 }} />
                {open ?<ChevronRight />:null}
              </ListItemButton>
              {open ? (
                //<div style={{ maxWidth: "100%", display: "block", height: "calc(100% - 30px)", overflowY: "auto" }} >
                  <List sx={{ paddingX: 1, display: "grid", }}>
                    {/* <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      className="mb-3"
                      fullWidth
                      onChange={(event: { target: { value: any; }; }) => setSearchQuery(event.target.value)}
                      sx={{ marginTop: 1, marginBottom: 2 }}
                    /> */}
                    {/* <FormControl variant="outlined" size="small" className="mb-3" fullWidth>
                      <InputLabel>Sort by</InputLabel>
                      <Select
                        value={sortOption}
                        onChange={(event) => setSortOption(event.target.value)}
                        label="Sort by"
                      >
                        <MenuItem value="newest" >Newest</MenuItem>
                        <MenuItem value="oldest">Oldest</MenuItem>
                      </Select>
                    </FormControl> */}

                    {
                      showHistory.map((data, index) => (
                        <Button
                          variant="text"
                          sx={{
                            textAlign: "left",
                            textTransform: "none",
                            width: "100%",
                            marginTop: 1,
                            fontWeight: ({
                              ...(data.isSummary ? { fontWeight: "bold" } : {})
                            })
                          }}
                          onClick={() => setQuestionFromHistory(data)}
                          key={`related-button-${index}`}
                        >
                          <div style={{ width: "100%" }}>
                            {data.question.length > 30 ? data.question.slice(0, 30) + "..." : data.question}
                          </div>
                        </Button>
                      ))
                    }
                    {/* {dbHistoryData.length !== showHistory.length && moreButtonRender && (
                      <Button
                        variant="outlined"
                        sx={{
                          width: "100%",
                          marginTop: 2,

                        }}
                        onClick={() => {
                          setMoreButtonRender(false);
                          setShowHistory(dbHistoryData);
                        }}
                      >
                        Load More...
                      </Button>
                    )} */}


                  </List>
                //</div>
              ) : null}
            </ListItem>

            
             <ListItem key={"Trending Content"} disablePadding sx={{ display: 'none', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
             <Link underline="none">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <TrendingUp />
                </ListItemIcon>
                <ListItemText primary={"Trending Content" } sx={{ opacity: open ? 1 : 0 }} />
                {open ?<ChevronRight />:null}
              </ListItemButton>
              </Link>
              </ListItem>
              <Divider /> 

<ListItem key={"City/District"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
<Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/City-District-Information.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Apartment />
                </ListItemIcon>
                <ListItemText primary={"City/District" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              <ListItem key={"Viewers and Calculators"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/AdvancedToolsViewers.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Calculate />
                </ListItemIcon>
                <ListItemText primary={"Viewers and Calculators" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              <ListItem key={"Forms"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/FETCH%20Online%20Forms.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <TextSnippet />
                </ListItemIcon>
                <ListItemText primary={"Forms" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              <ListItem key={"Directory & Contacts"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/Directory-Contacts.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Contacts />
                </ListItemIcon>
                <ListItemText primary={"Directory & Contacts" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              <ListItem key={"News Post"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/CSOD%20Knowledge%20Management/CSODNewsPost/SitePages/CSOD-Communications-News-Posts-Archive.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Newspaper />
                </ListItemIcon>
                <ListItemText primary={"News Post" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              <ListItem key={"Training Resources"} disablePadding sx={{ display: 'block', height: open ? "7vh" : "auto", maxHeight: "47vh" }}>
              <Link href="https://edisonintl.sharepoint.com/teams/FETCH/SitePages/Training-Resources.aspx" underline="none" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                sx={{
                  // color:"black",
                  minHeight: 30,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "#898989",
                  top: open?"0px" : "10px",
                  // border:"2px solid rgb(254, 209, 65)",
                  // borderRadius:"5px",
                  // bgcolor:"rgba(254, 209, 65, 0.5)"
                }}
                className={open ? 'mx-1 py-0 my-0' : ''}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ModelTrainingIcon />
                </ListItemIcon>
                <ListItemText primary={"Training Resources" } sx={{ opacity: open ? 1 : 0 }} />

              </ListItemButton>
              </Link>
              </ListItem>
              <Divider />
              
          </List>
          {/* <IconButton onClick={handleDrawerClose} sx={{
            "width": "40px",
            marginLeft: "auto",
            marginRight: "auto",
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "black",
              color: "white",
            }
          }}>
            {open ? < ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}

        </Drawer>
      </Box >
    );
  }
  return (
  
    <div className="container-fluid" style={{ display: "flex" }}>
      {/* <MiniDrawerComponent /> */}

      {renderMiniDrawer()}

      
    </div >
  );
};

export default MobileHomeComponent;
