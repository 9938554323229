import "../home.component.scss";

import * as React from "react";

import { Avatar } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { Paper } from "@mui/material";
import { RootState } from "../../../stores/store";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";


const WelcomeComponent = () => {
  const capabilities: any[] = [
    {
      id: "capability-1",
      text: "I'm here to help answer questions and provide information. How may I assist you today?",
    },
    // {
    //   id: "capability-2",
    //   text: "Allows user to provide follow-up corrections",
    // },
  ];

  // const limitations: any[] = [
  //   {
  //     id: "limitation-1",
  //     text: "May occasionally generate incorrect information",
  //   },
  //   {
  //     id: "limitation-3",
  //     text: "Limited knowledge \n(around SCE only)",
  //   },
  // ];

  const navigationReducer = useSelector((state: RootState) => state.navigation);
  const name = sessionStorage.getItem('username');
  var username = "";
  if(name!=null && name!=undefined){
  username = name?.split(' ')[0];
  }
  //const isMobile = window.matchMedia('(max-width: 767px)');
  return (
    <div className="col-12 welcome ">
      <Paper elevation={0}>
        <div className="row imgnew">
          <div className="col-12 mt-4">
            {/* <h1 className="introduction-title text-center">
              {navigationReducer.appName}
</h1> */}
          </div>

          {/* <div className="col-4 mt-3 offset-2 feature">
<div className="text-center">
<BoltIcon />
</div>
<h5 className="text-center mt-3">Capabilities</h5>
</div> */}
          {/* <div className="col-4 mt-3 feature">
<div className="text-center">
<WarningAmberIcon />
</div>
<h5 className="text-center mt-3">Limitations</h5>
</div> */}

          {/* <div className="col-2"> */}
          {isMobile ? null :
            <Avatar
              sx={{
                backgroundColor: "#FFFFFF",
                //</IconButton> bgcolor: "white",
                color: "#247B68",
                width: "30px",
                height: "30px",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "6px",
                marginTop: "73px",
                marginRight: "-5px",
              }}
            >
              <SmartToyIcon />
            </Avatar>}
          {/* <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-zbqwqy-MuiAvatar-root imgcard"
>Hello</div> */}
          {/* </div> */}
          {isMobile ?
            <div className="col-9">
              {capabilities.map((capability: any) => (
                <div
                  className="welcome-mobile-feature-card mt-2 mb-2 p-4 py-2"
                  //style={{ whiteSpace: "pre-line", display: "flex", alignItems: "center", justifyContent: "center" }}
                  key={capability.id}
                >
                  <div className='welcomeText'>{'Hello, '+username + '!' } </div>
                  <br />
                  <div> {capability.text}</div>
                </div>
              ))}
            </div> :
            <div className="col-7">
              {capabilities.map((capability: any) => (
                <div
                  className="welcomefeature-card mt-2 mb-2 p-4 "
                  //style={{ whiteSpace: "pre-line", display: "flex", alignItems: "center", justifyContent: "center" }}
                  key={capability.id}
                >
                  <div className='welcomeText'>{'Hello, '+username + '!' } </div>
                  <br />
                  <div className='welcomeText1'> {capability.text}</div>
                </div>
              ))}
            </div>}
          {/* <div className="col-4 text-center">
            {limitations.map((limitation: any) => (
<div
                className="feature-card mt-2 mb-2 p-4 py-2 rounded"
                style={{ whiteSpace: "pre-line", display: "flex", alignItems: "center", justifyContent: "center" }}
                key={limitation.id}
>
                {limitation.text}
</div>
            ))}
</div> */}
        </div>
      </Paper >
    </div >
  );
};

export default WelcomeComponent;
