import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FileInterface,
  FileSliceInterface,
} from "../../interfaces/files.interface";

const initialState: FileSliceInterface = {
  files: [],
  selected: [],
};

export const FileSlice = createSlice({
  name: "file",
  initialState: initialState,
  reducers: {
    bulkUpdateFileName: (state, action: PayloadAction<FileInterface[]>) => {
      state.files = action.payload;
    },
    addFile: (state, action: PayloadAction<FileInterface[]>) => {
      state.files = [...state.files, ...action.payload];
    },
    deleteFilesFromStore: (state, action: PayloadAction<string[]>) => {
      const tempFiles = state.files.filter(
        (file: FileInterface) => action.payload.indexOf(file.fileName) === -1
      );
      state.files = tempFiles;
    },
    setSelected: (state, action: PayloadAction<string[]>) => {
      state.selected = action.payload;
    },
  },
});

export const {
  bulkUpdateFileName,
  addFile,
  deleteFilesFromStore,
  setSelected,
} = FileSlice.actions;
export default FileSlice.reducer;
