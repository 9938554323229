import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#fed141",
    },
    divider: "rgba(255,160,0,0.31)",
  },
});

export default theme;
