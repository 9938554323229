import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  QuestionInterface,
  QuestionSliceInterface,
} from "../../interfaces/question.interface";

const initialState: QuestionSliceInterface = {
  questions: [],
};

export const HistorySlice = createSlice({
  name: "history",
  initialState: initialState,
  reducers: {
    addNewQuestion: (state, action: PayloadAction<QuestionInterface[]>) => {
      state.questions = [...state.questions, ...action.payload];
    },
    markEverythingAsHistory: (state) => {
      state.questions = state.questions.map((question: QuestionInterface) => {
        question.history = true;
        return question;
      });
    },
  },
});

export const { addNewQuestion, markEverythingAsHistory } = HistorySlice.actions;
export default HistorySlice.reducer;
